<template>
	<div class="phone-wrapper">
		<div class="phone">
			<img class="phone-background drop-shadow" :src="phone_img_url">
			<div class="phone-screen-wrapper">
				<div class="phone-inner">
					<Title :title="data.form.title" :title_style="'text-align: center;font-weight: 500;'"/>
					<div :key="child.id" v-for="child in data.form.children">
						<Item v-if="child.object_type == enums.ObjectType.ITEM" :item="child" :enums="enums" @toggle="toggle"/>
						<List v-else-if="child.object_type == enums.ObjectType.LIST" :list="child" :enums="enums" @toggle="toggle"/>
					</div>
				</div>
				<div class="update-btn">上傳</div>
			</div>
		</div>
	</div>
</template>

<script>
	import Title from './preview/Title';
	import Item from './preview/Item';
	import List from './preview/List';
	import phone_img from './assets/phone.png';
	export default {
		name: "Preview",
		props: ["data", "enums"],
		components: {
			Title,
			Item,
			List,
		},
		data() {
			return {
				phone_img_url: phone_img,
			}
		},
		methods: {
			toggle: function(input) {
				this.$emit('toggle', input)
			}
		}
	}
</script>

<style scoped>
	.phone-wrapper {
		margin-left: auto;
		margin-right: auto;
		height: 100%;
		text-align: center;
	}
	.phone {
		display: inline-block;
		margin-left: auto;
		margin-right: auto;
		height: 100%;
	}
	img.phone-background {
		height: 100%;
		width: auto;
		margin-left: auto;
		margin-right: auto;
		text-align: center;
		display: block;
		z-index: 0;
		background-color: rgba(0,0,0,0);
	}
	.phone-screen-wrapper {
		position: absolute;
		top: 3.5%;
		left: 6%;
		height: 93%;
		width: 88%;
		border-radius: 7%;
		background-color: #d5dde6;
		padding: 16px;
	}
	.phone-screen-wrapper * {
		background-color: rgba(0,0,0,0);
	}
	.phone-inner {
		overflow-x: hidden;
		overflow-y: scroll;
		margin-bottom: 1.5em;
		width: 100%;
		height: 96%;
	}
	.phone-inner::-webkit-scrollbar { width: 0 !important } /* chrome, safari*/
	.phone-inner { -ms-overflow-style: none; } /*IE 10+ */
	.phone-inner { overflow: -moz-scrollbars-none; } /* Firefox*/
	.phone-inner > div {
		border-radius: 8px;
	}
	.update-btn {
		border: 1px solid #2488c6;
		border-radius: 0.75em;
		background-color: #2488c6;
		color: #faf7f2;
		font-size: 0.75em;
		margin: 0.25em auto;
		padding: 0.35em 0;
		position: absolute;
		bottom: 0;
		text-align: center;
		width: calc(100% - 32px);
		width: -moz-calc(100% - 32px);
		width: -webkit-calc(100% - 32px);
	}
</style>