<template>
	<div class="item-wrapper">
		<Section v-if="item.item_type=='section'" :enums="enums" :section="item" @toggle="toggle"/>
		<Option v-else-if="item.item_type=='option'" :enums="enums" :option="item" @toggle="toggle"/>
		<ImageItem v-else-if="item.item_type=='image'" :enums="enums" :image="item" />
	</div>
</template>

<script>
	import Section from './Section';
	import Option from './Option';
	import ImageItem from './ImageItem';
	export default {
		name: "Item",
		props: ["item", "enums"],
		components: {
			Section,
			Option,
			ImageItem,
		},
		methods: {
			toggle: function(input) {
				this.$emit("toggle", input);
			},
		}
	}
</script>

<style scoped>
	.item-wrapper {
		background-color: transparent;
	}
</style>