<template>
	<div class="flex-row note">
		{{lang.form.option.note.hint}}：<input type="text" :value="note.hint" @input="inputHint" @change="changeHint">
	</div>
</template>

<script>
	import { getGenealogy } from '../assist';
	export default {
		name: "Note",
		props: ["note", "setting", "targets", "enums", "lang"],
		methods: {
			inputHint: function(e) {
				let input = {
					action: "modify",
					content: {
						hint: e.target.value,
					},
					record: false,
					genealogy: getGenealogy(this.note)
				};
				this.$emit("dataOperation", input);
			},
			changeHint: function(e) {
				let input = {
					action: "modify",
					content: {
						hint: e.target.value,
					},
					record: true,
					genealogy: getGenealogy(this.note)
				};
				this.$emit("dataOperation", input);
			},
		}
	}
</script>

<style scoped>
	.note {
		background-color: rgba(0,0,0,0);
		border-radius: 12px;
		margin-top: 6px;
		padding: 0 18px 6px;
		justify-content: space-between;
	}
	input[type="text"] {
		text-align: left;
		border: none;
		color: #000;
		border-bottom: 2px solid #ccc;
		flex: 1 1 80%;
		font-size: inherit;
	}
</style>