<template>
	<div class="creator-wrapper flex-row">
		<div class="loading" v-if="!data.ready"></div>
		<div id="Loading" v-if="!data.ready"></div>
		<div id="Creator" class="">
			<FormCreatorNav class="creator-nav" :language="data.language" :data="data.form" :json="data.json" :record="{redo: data.record.can_redo, undo: data.record.can_undo, save: data.record.can_save}" :enums="enums" @readFile="readFile" @operation="operate" @dataOperation="dataOperate" />
			<FormCreator class="creator-inner" :lang="data.language[data.language.lang]" :form="data.form" :setting="setting" :targets="data.items.targets" :enums="enums" @dataOperation="dataOperate" />
		</div>
		<div id="Viewer" class="hidden">
			<div class="viewer-inner">
				<Tab :data="data" :enums="enums" @toggle="previewToggle"/>
			</div>
		</div>
		<div id="PreviewButton" class="mobile-switch drop-shadow" @click="switchPreview">
			<i class="fas fa-mobile-alt"></i>
		</div>
	</div>
</template>

<script>
	import FormCreatorNav from './FormCreatorNav';
	import FormCreator from './FormCreator';
	import Tab from './Tab';
	import FormCreatorMixin from './formCreatorMixin.js';
	import axios from 'axios';
	
	export default {
		name: 'Creator',
		components: {
			FormCreatorNav,
			FormCreator,
			Tab
		},
		mixins: [FormCreatorMixin],
		props: ["json"],
		data() {
			return {
				data: {},
				query: {},
				url: "",
			}
		},
		created: function() {
			this.query = this.$route.query;
			if(typeof this.query.projectID === 'undefined' || typeof this.query.missionTypeID === 'undefined' || typeof this.query.itemID === 'undefined') {
				alert("Url error!")
				return;
			}
			this.url = `project/${this.query.projectID.toString()}/missionTypeFormData/v1/${this.query.missionTypeID.toString()}`;
			this.data = this.init();
			this.readJson(this.data, this.json);
			// this.recordStep(this.data);
			this.clearSteps(this.data)
			//this.save();
			this.requestGetJson();
		},
		methods: {
			readFile: function(file) {
				var self = this;
				var reader = new FileReader();
				reader.onload = function() {
					var obj = JSON.parse(reader.result);
					self.readJson(self.data, obj);
					self.recordStep(self.data);
					self.data.record.can_save = true;
				}
				if(file !== undefined) {
					reader.readAsText(file);
				}
			},
			operate: function(operation) {
				if(operation === "redo")
					this.redo(this.data);
				else if(operation === "undo")
					this.undo(this.data);
				else if(operation === "save") {
					this.save();
				}
			},
			dataOperate: function(input) {
				this.dataOperation(this.data, input);
				this.$forceUpdate();
			},
			previewToggle: function(input) {
				this.toggle(this.data, input);
				this.$forceUpdate();
			},
			save: function() {
				var result = this.checkJsonError(this.data);
				if(!result.success) {
					alert(result.msg);
					return;
				}
				this.data.record.can_save = false;
				this.requestUploadJson();
			},
			switchPreview: function() {
				var creator = document.getElementById("Creator");
				var viewer = document.getElementById("Viewer");
				var pre_btn = document.getElementById("PreviewButton");
				if(pre_btn.classList.value.includes("active")) {
					pre_btn.classList.remove("active");
					creator.classList.remove("hidden");
					viewer.classList.add("hidden");
				}
				else {
					pre_btn.classList.add("active");
					creator.classList.add("hidden");
					viewer.classList.remove("hidden");
				}
			},
			requestGetJson: function() {
				var self = this;
				this.request("post", this.url, {
						item_id: this.query.itemID//parseInt()
					}, function (response) {
						var data = {};
						if(typeof response.data.data !== "undefined") {
							data = response.data.data;
							if(typeof data === "object") {
								data = JSON.stringify(data);
							}
							data = JSON.parse(data);
						}
						self.readJson(self.data, data);
						self.clearSteps(self.data)
						self.data.ready = true;
					}, function (error) {
						self.data.ready = true;
						alert(error);
					});
			},
			requestUploadJson: function() {
				var self = this;
				this.request("post", `${this.url}/upload`, {
						mission_type_id: this.query.missionTypeID,//parseInt(),
						item_id: this.query.itemID,//parseInt(),
						data: JSON.stringify(this.data.json)
					}, function (response) {
						self.data.ready = true;
						alert(self.data.language[self.data.language.lang].message.save_successfully);
					}, function (error) {
						self.data.ready = true;
						alert(error);
					});
			},
			request: function(method, url, data, successFunc, errorFunc) {
				this.data.ready = false;
				const corsUrl = process.env.VUE_APP_CORS_URL;
				const baseUrl = `${this.query.protocol ? this.query.protocol : 'https'}://${this.query.domain ? this.query.domain : 'was.djtechtw.com'}/api/`

				var config = {
					method: method,
					baseURL: this.query.cors === 'true' ? corsUrl + baseUrl : baseUrl,
					url: url,
					data: data
				};
				axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
				axios.defaults.headers.common['Access-Control-Allow-Methods'] = "GET, POST, PATCH, PUT, DELETE, OPTIONS";
				axios.defaults.headers.common['Access-Control-Allow-Headers'] = "Origin, Content-Type, X-Auth-Token, X-Requested-With";
				axios(config).then(successFunc).catch(errorFunc);
			}
		},
	}
</script>


<style scoped>
	#Loading {
		position: absolute;
		z-index: 9999;
		height: 100%;
		width: 100%;
		background-color: rgba(0, 0, 0, 0.3);
	}
	.loading {
		position: absolute;
		z-index: 99999;
		height: 100%;
		width: 100%;
		background: transparent url("./assets/loading.gif") no-repeat;
		background-size: 200px;
		background-position: center;
	}
	@media screen and (min-width: 970px) {
		#Creator{
			min-width: 600px;
			flex: 1 1 60%;
			display: block;
		}
		#Viewer {
			min-width: 240px;
			flex: 1 1 40%;
			display: block;
		}
		.mobile-switch {
			display: none;
		}
	}
	@media screen and (max-width: 970px) {
		#Creator, #Viewer {
			width: 100%;
		}
		.hidden {
			display: none;
		}
	}
	.creator-wrapper {
		height: 100%;
	}
	#Creator {
		vertical-align: top;
		padding: 15px;
		height: calc(100% - 30px);
		height: -moz-calc(100% - 30px);
		height: -webkit-calc(100% - 30px);
	}
	#Creator .creator-nav {
		height: 45px;
		z-index: 999;
	}
	#Creator .creator-inner {
		position: absolute;
		padding: 15px;
		border: 1px solid #ccc;
		width: calc(100% - 30px);
		width: -moz-calc(100% - 30px);
		width: -webkit-calc(100% - 30px);
		height: calc(100% - 45px);
		height: -moz-calc(100% - 45px);
		height: -webkit-calc(100% - 45px);
		overflow-y: auto;
	}
	#Viewer {
		vertical-align: top;
		padding: 15px;
		height: 100%;
	}
	#Viewer .viewer-inner {
		height: 100%;
	}
	.mobile-switch {
		position: fixed;
		bottom: 25px;
		right: 25px;
		z-index: 9999;
		border: 1px solid #6d9bc6;
		border-radius: 100%;
		text-align: center;
		font-size: 50px;
	}
	.mobile-switch * {
		margin: 0 auto;
		background-color: rgba(0,0,0,0);
		color: #6d9bc6;
	}
	.mobile-switch:hover {
		background-color: #ddd;
	}
	.mobile-switch:hover * {
		color: #5d8bb6;
	}
	.mobile-switch.active {
		background-color: #6d9bc6;
	}
	.mobile-switch.active * {
		color: #eee;
	}
	.mobile-switch.active:hover {
		background-color: #5d8bb6;
	}
	.mobile-switch.active:hover * {
		color: #ddd;
	}
</style>