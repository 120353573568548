<template>
	<div class="flex-row selector-wrapper" :style="getStyle()">
		<Title :title="selector.title + '：'" :title_style="'text-align:left;left:0;'"/>
		<div :id="selector.id" class="text-blank" :class="selector.control.preview.enable ? '' : 'disable'" @click="openSelectList">
			<div class="selected-item">{{selector.control.preview.checked !== -1 ? selector.items[selector.control.preview.checked] : ""}}</div>
			<transition-group tag="ul" name="transit-selector" class="select-list">
				<li :key="item.id" v-for="(item, index) in selector.items" :index.prop="index" @click="selectItem">{{item.name}}</li>
			</transition-group>
		</div>
	</div>
</template>

<script>
	import Title from './Title';
	export default {
		name: "Selector",
		props: ["selector"],
		components: {
			Title,
		},
		created() {
		},
		methods: {
			openSelectList: function() {
				var target = document.getElementById(this.selector.id);
				if(target.children[1].classList.value.includes("open"))
					target.children[1].classList.remove("open");
				else
					target.children[1].classList.add("open");
			},
			selectItem: function(e) {
				this.selector.control.preview.checked = e.target.index;
			},
			getStyle() {
				if (!this.selector.control.preview.visible) {
					return 'display: none;';
				}
				return '';
			}
		}
	}
</script>

<style scoped>
	.selector-wrapper {
		width: 100%;
		justify-content: space-between;
		margin-right: 8px;
	}
	.text-blank {
		user-select: none;
		border-bottom: 1px solid #000;
		flex: 1 1;
		text-align: center;
		justify-content: space-between;
		align-items: flex-end;
		height: 1.5em;
	}
	.text-blank.disable {
		border-bottom-color: #aaa;
	}
	.text-blank.disable * {
		color: #aaa;
	}
	.text-blank.disable .select-list.open {
		display: none;
	}
	.selected-item {
		height: 1em;
	}
	.select-list {
		display: none;
		list-style: none;
		z-index: 99;
		font-size: 1em;
		border: 1px solid #777;
		border-top: none;
		background-color: #fafafa;
		border-radius: 0 0 4px 4px;
		max-height: 10rem;
		overflow-y: auto;
	}
	.select-list.open {
		display: block;
	}
	.select-list.open li {
		background-color: #fafafa;
	}
	.select-list.open li:last-child {
		border-radius: 0 0 4px 4px;
	}
	.select-list.open li:hover {
		color: #666;
		background-color: #eaeaf0;
	}
	.transit-selector-move {
  		transition: transform 1.5s;
	}
</style>