<template>
    <div class="flex-row rule-item">
        <div>
            <select class="rule-item-title" @change="changeRuleTarget">
                <option :value="-1" style="display:none"></option>
                <option :value="key" :selected="ruleItem.target && target.id === ruleItem.target.id" v-for="(target, key) in rule_targets" :key="key">{{target.name}}</option>
            </select>
        </div>
        <template v-if="ruleItem.target">
            <div class="rule-item-content"  v-if="ruleItem.target.type === enums.OptionType.RADIO_GROUP">
                <select @change="modifyRuleContent">
                    <option :value="-1"></option>
                    <option :value="index" :selected="index === ruleItem.content.checked" v-for="(item, index) in rule_targets.find(o=>o.id===ruleItem.target.id).items" :key="item.id">{{item}}</option>
                </select>
            </div>
            <div class="rule-item-content"  v-else-if="ruleItem.target.type === enums.OptionType.EDIT_TEXT || ruleItem.target.type === enums.OptionType.BI_EDIT_TEXT">
                <input type="text" :value="ruleItem.content.value" :placeholder="'value'" @input="modifyRuleContent"  @change="modifyRuleContent">
            </div>
        </template>
        <button class="close-btn" @click="removeRuleItem"></button>
    </div>
</template>

<script>
	import { getGenealogy, deepCopy } from '../assist';

	export default {
		name: "RuleItem",
		props: ["ruleItem", "controller", "rule", "ruleItems", "setting", "targets", "enums", "lang"],
		components: {
		},
		data() {
			return {
                rule_targets: []
			}
		},
		created() {
			this.rule_targets = this.getRuleTargets
		},
		watch: {
			targets: {
				deep: true,
				handler() {
					this.rule_targets = this.getRuleTargets
					this.$forceUpdate()
				}
			}
		},
		computed: {
			getRuleTargets() {
				return this.targets.filter((target) => {
					return target.id!==this.controller.id && this.rule.children.filter(o=>o.id!==this.ruleItems.id).map(o=>o.children).flat().findIndex(o=>o.target && o.target.id===target.id) == -1
				})
			}
		},
		methods: {
			changeRuleTarget: function(e) {
				let input = {
					action: "binding",
					content: {
                        target: e.target.value >=0 ? getGenealogy(this.rule_targets[e.target.value]) : undefined
					},
					record: true,
					genealogy: getGenealogy(this.ruleItem)
				};
				this.$emit("dataOperation", input);
				this.$forceUpdate()
			},
			modifyRuleContent: function(e) {
                let key = this.ruleItem.target.type === this.enums.OptionType.RADIO_GROUP || this.ruleItem.target.type === this.enums.OptionType.SELECTOR ? 'checked' : this.ruleItem.target.type === this.enums.OptionType.EDIT_TEXT ? 'value' : '';
                let content = {};
                content[key] = this.ruleItem.target.type === this.enums.OptionType.RADIO_GROUP || this.ruleItem.target.type === this.enums.OptionType.SELECTOR ? Number(e.target.value) : this.ruleItem.target.type === this.enums.OptionType.EDIT_TEXT ? e.target.value : '';
				let input = {
					action: "modify",
					content: content,
					record: true,
					genealogy: getGenealogy(this.ruleItem)
				};
				this.$emit("dataOperation", input);
			},
			removeRuleItem: function(e) {
				let input = {
					action: "remove",
					content: {
						id: this.ruleItem.id,
					},
					record: true,
					genealogy: this.ruleItem.ancestor_id
				};
				this.$emit("dataOperation", input);
			},
			focus: function() {
				this.$emit('focus')
			},
			blur: function() {
				this.$emit('blur')
			},
		}
	}
</script>

<style scoped>
	.rule {
		margin: 0 2em;
		width: 100%;
		font-size: 0.9em;
	}
	.effect-title {
		width: 30%;
		margin-right: 0.5em;
	}
	.effect-title:after {
		content: ":";
	}
	.add-all-btn, .remove-all-btn {
		margin: 1px 5px;
		padding: 0 3px;
		border-radius: 1em;
	}
	.rule-item-list {
		margin-right: 24px;
		border: 1px solid #ccc;
		padding: 6px 12px;
		border-radius: 12px;
	}
	.rule-item{
		justify-content: space-between;
		align-items: center;
		font-size: 0.9em;
	}
	.rule-item > div {
		align-items: center;
		justify-content: flex-start;
	}
	.rule-item-title {
        flex: 0 0 50%;
		line-height: 1em;
	}
	.rule-item-content {
		line-height: 1em;
		justify-content: flex-start;
		align-items: flex-end;
		width: 40%;
		margin-left: 5px;
	}
	.rule-item-content .key {
		width: 1.5em;
		font-size: 0.8em;
	}
	.rule-item .icon {
		margin-left: 5px;
	}
	.rule-item .icon.radioGroup:after{
		content: "\f00c";
		font-family: fontAwesome;
		color: #aaa;
	}
	.rule-item .icon.checkBoxes:after{
		content: "\f00c";
		font-family: fontAwesome;
		color: #aaa;
	}
	.rule-item .icon.editText:after{
		content: "\f303";
		font-family: fontAwesome;
		color: #aaa;
	}
	.rule-item .icon.image:after{
		content: "\f03e";
		font-family: fontAwesome;
		color: #aaa;
	}
	.rule-item-content {
		flex: 1 1;
	}
	.rule-item-content > * {
        width: 100%;
    }
	input[type="text"] {
		text-align: center;
		border: none;
		color: #000;
		border-bottom: 2px solid #ccc;
	}
	.close-btn {
		margin-right: 0.5em;
		background: transparent;
		border: none;
		height: 3em;
		z-index: 99;
	}
	.close-btn:after {
		color: red;
		align-content: center;
		content: "\f00d";
		font-family: fontAwesome;
	}
	.close-btn:hover:after {
		color: darkred;
		font-size: 1.5em;
	}

</style>