<template>
	<div class="item">
		<i v-if="(item.section_type!==this.enums.SectionType.BASIC_SECTION) && (item.type!==this.enums.OptionType.NOTE)" class="fa fa-align-justify handle"></i>
		<div>
		<!-- <draggable v-bind="dragOptions" @start="dragstart" @end="dragend"> -->
			<Section v-if="item.item_type==this.enums.ItemType.SECTION" :section="item" :setting="setting" :targets="targets" :enums="enums" :lang="lang" @dataOperation="dataOperation" @focus="focus" @blur="blur"/>
			<Option v-else-if="item.item_type==this.enums.ItemType.OPTION" :option="item" :setting="setting" :targets="targets" :enums="enums" :lang="lang" @dataOperation="dataOperation" @focus="focus" @blur="blur"/>
			<ImageItem v-else-if="item.item_type===this.enums.ItemType.IMAGE" :image="item" :setting="setting" :targets="targets" :enums="enums" :lang="lang" @dataOperation="dataOperation" @focus="focus" @blur="blur"/>
			<InfoItem v-else-if="item.item_type===this.enums.ItemType.INFO_ITEM" :infoItem="item" :setting="setting" :targets="targets" :enums="enums" :lang="lang" @dataOperation="dataOperation" @focus="focus" @blur="blur"/>
		<!-- </draggable> -->
		</div>
	</div>
</template>

<script>
	import Section from './Section';
	import ImageItem from './ImageItem';
	import Option from './Option';
	import InfoItem from './InfoItem';
	import { getGenealogy } from '../assist';

	export default {
		name: "Item",
		components: {
			Section,
			ImageItem,
			Option,
			InfoItem,
		},
		props: ["item", "setting", "targets", "enums", "lang"],
		data() {
			return {
				focusText: false
			}
		},
		computed: {
		},
		methods: {
			dataOperation: function(input) {
				this.$emit("dataOperation", input);
			},
			focus: function() {
				this.focusText = true
				this.$emit('focus')
			},
			blur: function() {
				this.focusText = false
				this.$emit('blur')
			}
		}
	}
</script>

<style scoped>
	.item {
		background-color: rgba(0,0,0,0);
		font-size: 16px;
	}
	.chosen {
		background-color: rgba(224, 224, 240, 0.6);
	}
	.dragged {
		background-color: rgba(224, 224, 240, 0.4);
	}
	
</style>