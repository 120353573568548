<template>
	<div class="nav">
		<ul class="menu">
			<li class="hasdrop"><i class="fas fa-bars"></i>
				<ul class="sublist first-sublist">
					<li onClick="this.children[0].click();">
						<input class="input-btn-" style="display:none;" type="file" accept=".json" @change="readFile" >
						<i class="fas fa-upload fa-fw"><font>{{lang.nav.upload}}</font></i> 
					</li>
					<li @click="downloadFile">
						<i class="fas fa-download fa-fw"><font>{{lang.nav.download}}</font></i>
					</li>
					<li class="hasdrop">
						<i class="fas fa-cog fa-fw"><font>{{lang.nav.setting}}</font></i>
						<ul class="sublist second-sublist">
							<li class="hasdrop">
								<i class="fas fa-language fa-fw"><font>{{lang.nav.language}}</font></i>
								<ul class="sublist third-sublist check-list">
									<li v-for="(lang_value, key) in language.list" :key="key" :lang.prop="key" @click="changeLanguage">
										<i :class="language.lang === key ? 'checked' : 'unchecked'" :lang.prop="key"><font :lang.prop="key">{{lang_value}}</font></i>
									</li>
								</ul>
							</li>
						</ul>
					</li>
				</ul>
			</li>
			<li><i class="fas fa-reply" :title="lang.nav.redo" @click="redo" :class="record.redo ? '' : 'disable'"></i></li>
			<li><i class="fas fa-share" :title="lang.nav.undo" @click="undo" :class="record.undo ? '' : 'disable'"></i></li>
			<li><i class="fas fa-save" :title="lang.nav.save" @click="save" :class="record.save ? '' : 'disable'"></i></li>
			<li class="right"><i class="far fa-images" :title="lang.nav.image" @click="addOrRemoveImages" :class="hasImages() ? 'active' : ''"></i></li>
			<li class="right"><i class="far fa-sticky-note" :title="lang.nav.note" @click="addOrRemoveNote" :class="hasNote() ? 'active' : ''"></i></li>
		</ul>
	</div>
</template>

<script>
import { getGenealogy } from './assist';
export default {
	name: "FormCreatorNav",
	props: ["data", "json", "record", "language"],
	data() {
		return {
			lang: {}
		}
	},
	created() {
		this.lang = this.language[this.language.lang];
	},
	updated() {
		this.lang = this.language[this.language.lang];
	},
	methods: {
		dropMenu: function(e) {
			if(e.target.parentNode.classList.value.includes("open"))
				e.target.parentNode.classList.remove("open");
			else {
				e.target.parentNode.classList.add("open");
			}
		},
		hasImages: function() {
			if(typeof this.data.children === "undefined")
				return false;
			return typeof this.data.children.find(o => o.list_type == "images") !== "undefined";
		},
		hasNote: function() {
			if(typeof this.data.children === "undefined")
				return false;
			var sections = this.data.children.find(o => o.list_type == "sections");
			var note = undefined;
			if(typeof sections !== "undefined")
				note = sections.children.find(o => o.section_type == "noteSection");
			return typeof note !== "undefined";
		},
		redo: function(e) {
			if(!this.record.redo)
				return;
			this.$emit('operation', 'redo');
		},
		undo: function(e) {
			if(!this.record.undo)
				return;
			this.$emit('operation', 'undo');
		},
		save: function(e) {
			if(!this.record.save)
				return;
			this.$emit('operation', 'save');
		},
		addOrRemoveImages: function() {
			var images = this.data.children.find(o => o.list_type == "images");
			let input = {}
			if(typeof images === "undefined") {
				input = {
					action: "add",
					content: {
						object_type: "list",
						list_type: "images",
					},
					record: true,
					genealogy: getGenealogy(this.data)
				};
			}
			else {
				input = {
					action: "remove",
					content: {
						id: images.id,
					},
					record: true,
					genealogy: getGenealogy(this.data)
				};
			}
			this.$emit("dataOperation", input);
		},
		addOrRemoveNote: function() {
			var sections = this.data.children.find(o => o.list_type == "sections");
			var note = undefined;
			if(typeof sections !== "undefined")
				note = sections.children.find(o => o.section_type == "noteSection");
			let input = {}
			if(typeof note == "undefined") {
				input = {
					action: "add",
					content: {
						object_type: "item",
						item_type: "section",
						type: "noteSection",
					},
					record: true,
					genealogy: getGenealogy(sections)
				};
			}
			else {
				input = {
					action: "remove",
					content: {
						id: note.id,
					},
					record: true,
					genealogy: getGenealogy(sections)
				};
			}
			this.$emit("dataOperation", input);
		},
		readFile: function(e) {
			if(typeof e.target.files[0] !== "undefined") {
				this.$emit('readFile', e.target.files[0]);
			}
			e.target.value = '';
		},
		downloadFile: function() {
			var download = function (content, filename) {
				let link = document.createElement('a');
				link.download = filename;
				link.style.display = 'none';
				// 字元內容轉變成blob地址
				let blob = new Blob([content]);
				link.href = URL.createObjectURL(blob);
				document.body.appendChild(link);
				link.click();
				document.body.removeChild(link);
			};
			var content = this.json;
			var title = this.isEmpty(this.json.title) ? "dynamic_form" : this.json.title;
			var filecontent = JSON.stringify(content)
			if ('download' in document.createElement('a')) {
				download(filecontent, title + '.json')
			} else {
				alert('瀏覽器不支援')
			}
		},
		changeLanguage: function(e) {
			this.language.lang = e.target.lang;
		}
	}
}
</script>

<style scoped>
	.nav {
		height: 45px;
		background-color: #0000000f;
		z-index: 999999;
		border: 1px solid #ccc;
		border-bottom: none;
	}
	.nav * {
		color: #555;
		background-color: transparent;
	}
	.nav ul {
		list-style-type: none;
		height: 100%;
		width: 100%;
	}
	.nav ul li {
		float: left;
		height: 100%;
	}
	.nav ul li.right {
		float: right;
		height: 100%;
	}
	ul.sublist li {
		box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.5);
	}
	.check-list i {
		height: 100%;
	}
	.check-list i * {
		vertical-align: top;
		font-style: normal;
	}
	.check-list i:before{
		vertical-align: 0.5em;
		margin: 0 10px;
		content: "\f00c";
		font-family: fontawesome;
		font-size: 15px;
	}
	.check-list .checked:before {
		color: #777;
	}
	.check-list .unchecked:before {
		color: #ddd;
	}
	.sublist .hasdrop > i:after {
		margin-left: 0.5em;
		content: "\f0da";
		font-family: fontawesome;
		font-size: 15px;
		vertical-align: middle;
	}
	.nav .hasdrop > ul {
		display: none;
		width: 165px;
		z-index: 100;
		height: 100%;
		position: absolute;
		left: 165px;
	}
	.nav .hasdrop > ul.first-sublist {
		float: left;
		top: 45px;
		left: 0;
	}
	.nav .hasdrop:hover > ul, .nav .hasdrop.open > ul {
		display: inline-block;
	}
	.nav .hasdrop > ul li {
		font-size: 1.1em;
		user-select: none;
		-moz-user-select: none;
		-webkit-user-select: none;
		-ms-user-select: none;
		background-color: #fafafa;
		width: 100%;
		clear: both;
		height: 40px;
	}
	.nav .hasdrop > ul li:hover {
		color: #000;
	}
	.nav i {
		font-size: 35px;
		margin: 0 0.1em;
		padding: 0.1em;
		width: 1.5em;
		height: 100%;
		z-index: 99;
		text-align: center;
		vertical-align: middle;
	}
	i > * {
		font-size: 0.55em;
		margin-left: 0.3em;
		vertical-align: middle;
		text-align: center;
	}
	i:hover > * {
		color: #000;
	}
	i:hover {
		color: #000;
	}
	i.active {
		color: #777;
		font-weight: bold;
		border-style: inset;
	}
	i.disable {
		color: #aaa;
	}
	li.check-list {

	}
</style>