<template>
	<div class="option">
		<div class="flex-row">
			<div class="flex-row option-select" v-if="option.type !== enums.OptionType.NOTE">
				<select class="option-type-select" @change="changeOptionType">
					<template v-for="option_type in setting.option.normal">
						<option :key="option_type" :value="option_type" :selected="option.type === option_type" v-show="!(option.isExpand && !setting.option.expand.includes(option_type))">{{lang.form.option[option_type].name}}</option>
					</template>
				</select>
			</div>
			<div class="flex-column option-content">
				<div v-if="option.type==enums.OptionType.BI_EDIT_TEXT" :class="option.type">
					<BiEditText :biEditText="option" :setting="setting" :targets="targets" :enums="enums" :lang="lang" @dataOperation="dataOperation" @focus="focus" @blur="blur"/>
				</div>
				<div v-else-if="option.type==enums.OptionType.EDIT_TEXT" :class="option.type">
					<EditText :editText="option" :setting="setting" :targets="targets" :enums="enums" :lang="lang" @dataOperation="dataOperation" @focus="focus" @blur="blur"/>
				</div>
				<div v-else-if="option.type==enums.OptionType.RADIO_GROUP" :class="option.type">
					<RadioGroup :radioGroup="option" :setting="setting" :targets="targets" :enums="enums" :lang="lang" @dataOperation="dataOperation" @focus="focus" @blur="blur"/>
				</div>
				<div v-else-if="option.type==enums.OptionType.CHECK_BOXES" :class="option.type">
					<CheckBoxes :checkBoxes="option" :setting="setting" :targets="targets" :enums="enums" :lang="lang" @dataOperation="dataOperation" @focus="focus" @blur="blur"/>
				</div>
				<div v-else-if="option.type==enums.OptionType.SELECTOR" :class="option.type">
					<Selector :selector="option" :setting="setting" :targets="targets" :enums="enums" :lang="lang" @dataOperation="dataOperation" @focus="focus" @blur="blur" />
				</div>
				<div v-else-if="option.type==enums.OptionType.NOTE" :class="option.type">
					<Note :note="option" :setting="setting" :targets="targets" :enums="enums" :lang="lang" @dataOperation="dataOperation" @focus="focus" @blur="blur" />
				</div>
				<div class="child" :class="child.type" v-for="child in option.children" :key="child.id">
					<Option v-if="child.item_type==enums.ItemType.OPTION" :option="child" :setting="setting" :targets="targets" :enums="enums" :lang="lang" @dataOperation="dataOperation" @focus="focus" @blur="blur" />
				</div>
			</div>
			<div class="option-controller" v-if="!option.isExpand && option.type!==enums.OptionType.NOTE">
				<button class="control-btn" @click="openOrCloseControlList"><i class="fas fa-ellipsis-v"></i></button>
				<div class="option-controller-list" v-if="open_control">
					<div class="expansion-btn link" @click="addOrRemoveChild"><i :class="option.children.length > 0 ? 'fas fa-edit' : 'far fa-edit'"></i><font>{{lang.form.option.expansion}}</font></div>
					<!--div class="copy-btn link" @click="copyOption"><i class="far fa-copy"></i><font>複製</font></div-->
				</div>
			</div>
			<button class="close-btn" @click="removeOption"></button>
		</div>
	</div>
</template>

<script>
	import EditText from './EditText';
	import BiEditText from './BiEditText';
	import RadioGroup from './RadioGroup';
	import CheckBoxes from './CheckBoxes';
	import Selector from './Selector';
	import Note from './Note';
	import Option from './Option';
	import { getGenealogy } from '../assist';

	export default {
		name: "Option",
		props: ["option", "setting", "targets", "enums", "lang"],
		data() {
			return {
				open_control: false,
			}
		},
		components: {
			EditText,
			BiEditText,
			RadioGroup,
			CheckBoxes,
			Selector,
			Note,
			Option
		},
		methods: {
			dataOperation: function(input) {
				this.$emit("dataOperation", input);
			},
			removeOption: function() {
				let input = {
					action: "remove",
					content: {
						id: this.option.id,
					},
					record: true,
					genealogy: this.option.ancestor_id
				};
				this.$emit("dataOperation", input);
			},
			changeOptionType: function(e) {
				let input = {
					action: "change",
					content: {
						type: e.target.value,
					},
					record: true,
					genealogy: getGenealogy(this.option)
				};
				this.$emit("dataOperation", input);
			},
			addOrRemoveChild: function(e) {
				if(this.option.children.length <= 0) {
					let input = {
						action: "add",
						content: {
							object_type: this.enums.ObjectType.ITEM,
							item_type: this.enums.ItemType.OPTION,
							type: this.enums.OptionType.EDIT_TEXT,
							data: { isExpand: true },
						},
						record: true,
						genealogy: getGenealogy(this.option)
					};
					this.$emit("dataOperation", input);
				}
				else {
					let input = {
						action: "remove",
						content: {
							id: this.option.children[0].id,
						},
						record: true,
						genealogy: getGenealogy(this.option)
					};
					this.$emit("dataOperation", input);
				}
				this.openOrCloseControlList();
			},
			copyOption: function() {
				// var content = Object.assign({}, content, this.option.content);
				// var children = [...this.option.children];
				// var input = {
				// 	type: "add",
				// 	content: {
				// 		object: "item",
				// 		type: this.option.type,
				// 		title: this.option.title,
				// 		//index: this.option.index,
				// 		content: content,
				// 		children: children,
				// 	},
				// 	record: true,
				// 	parent: []
				// };
				// this.$emit("dataOperation", input);

				// this.openOrCloseControlList();
			},
			openOrCloseControlList: function() {
				this.open_control = !this.open_control;
			},
			focus: function() {
				this.$emit('focus')
			},
			blur: function() {
				this.$emit('blur')
			},
		}
	}
</script>

<style scoped>
	.option {
		width: 100%;
	}
	.option-content {
		display: block;
		width: 100%;
		margin: 0 1em;
	}
	.option-content > div {
		display: flex;
		flex-direction: row;
		justify-content: flex-start;
		width: 100%
	}
	.option-type-select {
		height: 1.5em;
		margin: 1.5em 0 auto 1em;
		font-size: 0.8em;
	}
	.option .note, .option .radioGroup, .option .checkBoxes, .option .editText, .option .biEditText {
		width: 100%;
	}
	.close-btn {
		margin-left: 0.5em;
		background: transparent;
		border: none;
		height: 3em;
		z-index: 99;
	}
	.close-btn:after {
		color: red;
		align-content: center;
		content: "\f00d";
		font-family: fontAwesome;
	}
	.close-btn:hover:after {
		color: darkred;
		font-size: 1.5em;
	}
	.option-controller-list {
		z-index: 99;
		position: absolute;
		top: 2.5em;
		right: 0;
		width: 4.5em;
	}
	.option-controller-list > * {
		background-color: #fff;
		padding: 0 5px;
		position: absolute;
		right: 0;
		border: none;
		font-size: 1em;
		height: 1.5em;
		color: #555;
		flex: 0 0 1em;
	}
	.option-controller-list > * * {
		background-color: rgba(0,0,0,0);
	}
	.option-controller-list :hover * {
		color: #000;
	}
	.control-btn {
		position: absolute;
		right: 0;
		border: none;
		font-size: 1em;
		height: 1em;
		color: #555;
		margin-top: 12px;
		flex: 0 0 1em;
		background-color: rgba(0,0,0,0);
	}
	.expansion-btn {
	}
	.copy-btn {
		top: 1.5em;
	}
	.child {
		width: 100%;
	}
	.hidden {
		display: none;
	}
</style>