<template>
	<div class="bi-edit">
		<draggable v-bind="dragOptions" @end="dragend" handle=".handle">
			<template v-for="editText in biEditText.editTexts">
				<EditText :key="editText.id" :editText="editText" :setting="setting" :targets="targets" :enums="enums" :lang="lang" @dataOperation="dataOperation" @focus="focus" @blur="blur"/>
			</template>
		</draggable>
	</div>
</template>

<script>
	import EditText from './EditText';
	import { getGenealogy } from '../assist';
	import draggable from 'vuedraggable';

	export default {
		name: "BiEditText",
		props: ["biEditText", "setting", "targets", "enums", "lang"],
		components: {
			EditText,
			draggable
		},
		computed: {
			dragOptions() {
				return {
					group: this.biEditText.editTexts,
					disabled: false,
					chosenClass: "chosen", // 拖曳目標的class
					dragClass: "dragged", // 拖曳中的class（滑鼠上）
					forceFallback: true,
					animation: "300",
					scrollSensitivity: "100",
				};
			},
		},
		methods: {
			dataOperation: function(input) {
				this.$emit("dataOperation", input);
			},
			focus: function() {
				this.$emit('focus')
			},
			blur: function() {
				this.$emit('blur')
			},
			dragend: function(e) {
				let input = {
					action: "sort",
					content: {
						type: "biEditText",
						old: {
							index: e.oldIndex,
						},
						new: {
							index: e.newIndex,
						}
					},
					record: true,
					genealogy: getGenealogy(this.biEditText)
				};
				this.$emit("dataOperation", input);
			},
		}
	}
</script>

<style scoped>
	.bi-edit {
		width: 100%;
		display: block;
	}
	.bi-edit > EditText {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
	}
	.chosen {
		background-color: rgba(224, 224, 240, 0.6);
	}
	.dragged {
		background-color: rgba(224, 224, 240, 0.4);
	}
	.handle {
		float: left;
		padding-top: 8px;
		padding-bottom: 8px;
	}
</style>