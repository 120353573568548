<template>
	<div class="title">
		<input type="text" :value="title" @input="input" @change="change" :placeholder="placeholder" :readonly="readonly" @focus="focus" @blur="blur">
	</div>
</template>

<script>
	export default {
		name: "Title",
		props: ["title", "placeholder", "readonly"],
		components: {
		},
		methods: {
			input: function(e) {
				this.$emit("input", e.target.value);
			},
			change: function(e) {
				this.$emit("change", e.target.value);
			},
			focus: function() {
				this.$emit('focus')
			},
			blur: function() {
				this.$emit('blur')
			},
		}
	}
</script>

<style scoped>
	.title{
		/*border: 1px solid blue;*/
		padding: 0 20px;
		background-color: transparent;
		overflow-y: hidden;
		line-height: 1em;
	}
	input[type="text"]{
		display: inline-block;
		padding: 4px;
		text-align: center;
		font-size: 1.5em;
		width: 100%;
		background-color: transparent;
		border: none;
		border-bottom: 2px solid #ccc;
		/*border-radius: 12px;*/
		color: #000;
	}
</style>