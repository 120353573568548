<template>
	<div class="flex-row check-boxes-wrapper" :style="getStyle()">
		<Title class="title" :title="checkBoxes.title + '：'" :title_style="'text-align:left;left:0;'"/>
		<transition-group tag="div" name="transit-checkBoxes" class="check-boxes-items" :class="checkBoxes.control.preview.enable ? '' : 'disable'">
			<div class="check-boxes-item-btn" :key="item.id" v-for="(item, index) in checkBoxes.items" @click="toggle" :index.prop="index">
				<i :index.prop="index" :class="checkBoxes.control.preview.checked.includes(index) ? 'fas fa-check-square selected' : 'far fa-square'"></i>
                {{item.name}}
			</div>
		</transition-group>
	</div>
</template>

<script>
	import Title from "./Title";
	import { getGenealogy } from '../assist';
	export default {
		name: "CheckBoxes",
		props: ["checkBoxes"],
		components: {
			Title,
		},
        data() {
			return {
                checked: [],
			}
		},
		created() {
            this.checked = this.checkBoxes.control.preview.checked;
		},
		methods: {
			toggle: function(e) {
				if(!this.checkBoxes.control.preview.enable)
					return;
                
                if(!this.checked.includes(e.target.index)){
                    this.checked.push(e.target.index);
                } else {
                    if (this.checked.indexOf(e.target.index) !== -1) {
                        this.checked.splice(this.checked.indexOf(e.target.index), 1);
                    }
                }
				let input = {
					content: {
						checked: this.checked
					},
					genealogy: getGenealogy(this.checkBoxes)
				}
				this.$emit("toggle", input);
			},
			getStyle() {
				// if (!this.radioGroup.control.preview.visible) {
				// 	return 'display: none;';
				// }
				// return '';
			}
		}
	}
</script>

<style scoped>
	.check-boxes-wrapper {
		width: 100%;
		justify-content: space-between;
		margin-right: 8px;
	}
	.title {
		/* min-width: 40%; */
	}
	.check-boxes-items {
		flex: 1 1;
	}
	.check-boxes-items .check-boxes-item-btn {
		display: inline-block;
		float: left;
		width: fit-content;
		height: 2em;
		margin: 0 2%;
		/* border: 2px solid #6d9bc6; */
		border-radius: 4px;
		color: #6d9bc6;
        font-size: 15px;
	}
	.check-boxes-items .check-boxes-item-btn.checked {
		background-color: #6d9bc6;
		color: #faf7f2;
	}
	.check-boxes-items.disable .check-boxes-item-btn {
		border: 2px solid #aaa;
		background-color: #aaa;
		color: #faf7f2;
	}
	.transit-checkBoxes-move {
  		transition: transform 1.5s;
	}
</style>