<template>
	<div class="flex-column image">
		<div class="flex-row">
			<div class="title">
				<input class="input-text" type="text" :value="image.title" @input="inputTitle" @change="changeTitle" :placeholder="lang.form.title.title" @focus="focus" @blur="blur">
				<div class="warning" v-if="image.control.errors.duplicate_title" :title="lang.message.duplicate_title">*{{lang.message.duplicate_title}}</div>
				<div class="warning" v-if="image.control.errors.invalid_title" :title="lang.message.invalid_title">*{{lang.message.invalid_title}}</div>
			</div>
			<button class="close-btn" @click="removeImage"></button>
		</div>
		<div class="flex-column content">
			<div class="flex-row">
				<div>{{lang.form.image.drawingMode.name}}：</div>
				<select @change="changeDrawingMode">
					<option :value="mode" :selected="image.drawingMode === mode" v-for="mode in setting.drawing.mode" :key="`drawing_mode_${mode}`">{{lang.form.image.drawingMode[mode]}}</option>
				</select>
			</div>
			<div class="flex-row" v-if="setting.drawing.type[image.drawingMode]">
				<div>{{lang.form.image.drawingType.name}}：</div>
				<select @change="changeDrawingType">
					<option style="display:none"></option>
					<template v-for="type in setting.drawing.type[image.drawingMode]">
						<option :key="`drawing_type_${type}`" :value="type" :selected="image.drawingType === type">{{lang.form.image.drawingType[type]}}</option>
					</template>
				</select>
			</div>
			<div class="flex-row" v-if="setting.drawing.type[image.drawingMode] && image.drawingType">
				<div>{{lang.form.image.info.name}}：</div>
				<div class="flex-column info">
					<div class="info-list-wrapper" v-for="(infoItems, key) in image.infos.children" :key="key">
						<InfoItems :infoItems="infoItems" :controller="image" :setting="setting" :targets="targets" :enums="enums" :lang="lang" @dataOperation="dataOperation" @focus="focus" @blur="blur"/>
						<!-- <List :list="infoItems" :setting="setting" :targets="targets" :enums="enums" :lang="lang" @dataOperation="dataOperation" @focus="focus" @blur="blur"/> -->
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import VueJsonPretty from 'vue-json-pretty';
	import InfoItems from './InfoItems';
	// import InfoItem from './InfoItem';
	import { getGenealogy } from '../assist';
	export default {
		name: "ImageItem",
		components: {
			VueJsonPretty,
			InfoItems,
			// InfoItem,
		},
		props: ["image", "lang", "setting", "enums", "targets"],
		data() {
			return {
				ruleItems: [],
			}
		},
		created() {
		},
		methods: {
			dataOperation: function(input) {
				this.$emit("dataOperation", input)
			},
			removeImage: function(e) {
				let input = {
					action: "remove",
					content: {
						id: this.image.id,
					},
					record: true,
					genealogy: this.image.ancestor_id
				};
				this.$emit("dataOperation", input);
			},
			inputTitle: function(e) {
				let input = {
					action: "modify",
					content: {
						title: e.target.value,
					},
					record: false,
					genealogy: getGenealogy(this.image)
				};
				this.$emit("dataOperation", input);
			},
			changeTitle: function(e) {
				let input = {
					action: "modify",
					content: {
						title: e.target.value,
					},
					record: true,
					genealogy: getGenealogy(this.image)
				};
				this.$emit("dataOperation", input);
			},
			changeDrawingMode: function(e) {
				let input = {
					action: "modify",
					content: {
						drawingMode: e.target.value,
						drawingType: null
					},
					record: true,
					genealogy: getGenealogy(this.image)
				};
				this.$emit("dataOperation", input);
			},
			changeDrawingType: function(e) {
				let input = {
					action: "modify",
					content: {
						drawingType: e.target.value
					},
					record: true,
					genealogy: getGenealogy(this.image)
				};
				this.$emit("dataOperation", input);
			},
			focus: function() {
				this.$emit("focus")
			},
			blur: function() {
				this.$emit("blur")
			}
		}
	}
</script>

<style scoped>
	.image *{
		background-color: transparent;
	}
	.image {
		min-height: 5em;
		border: 2px solid #354E84;
		border-radius: 12px;
		margin: 6px 12px 12px;
		background-color: transparent;
	}
	.close-btn {
		margin: 0 0.5em;
		background: transparent;
		border: none;
		height: 3em;
		z-index: 99;
	}
	.close-btn:after {
		color: red;
		align-content: center;
		content: "\f00d";
		font-family: fontAwesome;
	}
	.close-btn:hover:after {
		color: darkred;
		font-size: 1.5em;
	}
	.title {
		margin: 0.5em;
		background-color: transparent;
	}
	.input-text {
		line-height: 1em;
	}
	input[type="text"] {
		font-size: 1em;
		width: 100%;
		text-align: center;
		border: none;
		color: #000;
		border-bottom: 2px solid #ccc;
		background-color: transparent;
	}
	.warning {
		margin: 0;
		width: 100%;
		color: #a33;
		z-index: 99999;
		word-wrap: break-word;
		white-space: nowrap;
		text-overflow: ellipsis;
		overflow: hidden;
	}
	.content {
		margin-left: 3em;
		flex: 1 1;
		/* font-size: 0.8em; */
	}
	.content > div.flex-row {
		margin-bottom: .5em;
	}
	.content > div.flex-row div:first-child {
		flex: 0 1 20%;
	}
	.content > div.flex-row div {
		flex: 1 1;
	}
	.info-amount {
		margin: 0 auto;
	}
	.info > div.flex-row {
		flex: 1 1;
		align-items: center;
		background-color: transparent;
		justify-content: flex-start;
	}
	.info-list-wrapper {
		flex: 1 1;
		/* border: 2px solid #354E84; */
		/* border-radius: 12px; */
		background-color: transparent;
		margin: 6px 12px 12px;
		padding: 6px 0;
		border-bottom: .5px solid #aaa;
	}
	.info-list-wrapper:last-child {
		border: none
	}
	.info-list-wrapper > * {
		background-color: transparent;
		margin-left: 24px;
	}
	.info-list-wrapper > *:first-child {
		margin-left: auto;
	}
	.info-control, .info-content {
		font-size: 0.8em;
	}
	.info-control {
		height: min-content;
	}
	.info-content > * {
		margin: .25rem;
		background-color: transparent;
		height: min-content;
	}
</style>