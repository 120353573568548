import Vue from 'vue'
import App from './App.vue'
//import FormCreator from './form-creator.js'
import {Common} from './CommonMixin.js'
import router from './plugins/router'

Vue.config.productionTip = false
Vue.mixin(Common);

new Vue({
  render: h => h(App),
  router
}).$mount('#app')
