<template>
	<div class="note-wrapper">
		<div class="hint">{{note.hint}}</div>
	</div>
</template>

<script>
	export default {
		name: "Note",
		props: ["note"],
		methods: {

		}
	}
</script>

<style scoped>
	.note-wrapper {
		border: 2px solid #354E84;
		border-radius: 8px;
		min-height: 96px;
		margin-bottom: 2px;
	}
	.hint {
		font-size: 0.8em;
		color: #999;
		padding: 8px;
		background-color: transparent;
	}
</style>