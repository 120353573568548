import { render, staticRenderFns } from "./InfoItems.vue?vue&type=template&id=27beb1dc&scoped=true&"
import script from "./InfoItems.vue?vue&type=script&lang=js&"
export * from "./InfoItems.vue?vue&type=script&lang=js&"
import style0 from "./InfoItems.vue?vue&type=style&index=0&id=27beb1dc&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "27beb1dc",
  null
  
)

export default component.exports