<template>
	<div class="flex-row edit">
		<div class="flex-column title">
			<input class="input-text" type="text" :value="editText.title" @input="inputTitle" @change="changeTitle" :placeholder="lang.form.option.title" @focus="focus" @blur="blur">
			<div class="warning" v-if="editText.control.errors.duplicate_title" :title="lang.message.duplicate_title">*{{lang.message.duplicate_title}}</div>
			<div class="warning" v-if="editText.control.errors.invalid_title" :title="lang.message.invalid_title" :value="lang.message.invalid_title">
				*{{lang.message.invalid_title}}
			</div>
		</div>
		<div class="flex-column controller">
			<button class="hidden-btn" @click="showOrHiddenContent"><i :class="show_content ? 'fas fa-caret-down' : 'fas fa-caret-right'"></i></button>
		</div>
		<div class="flex-column content">
			<div class="flex-row value">
				<div class="text-blank">[{{lang.form.option.editText.value}}]</div>
				<input class="input-text" type="text" :value="editText.unit" :placeholder="lang.form.option.editText.unit" @input="inputUnit" @change="changeUnit" @focus="focus" @blur="blur">
			</div>
			<div class="flex-column setting" v-if="show_content">
				<div class="flex-row">
					<div>{{lang.form.option.editText.type.name}}: </div>
					<select @change="changeInputType" >
						<option value="text" :selected="editText.input_type === 'text'">{{lang.form.option.editText.type.text}}</option>
						<option value="number" :selected="editText.input_type === 'number'">{{lang.form.option.editText.type.number}}</option>
					</select>
				</div>
				<div class="flex-row">
					<div>{{lang.form.option.editText.focus.name}}: </div>
					<select @change="changeFocus">
						<option value="isFocus" :selected="editText.isFocus === true">{{lang.form.option.editText.focus.isFocus}}</option>
						<option value="notFocus" :selected="editText.isFocus === false">{{lang.form.option.editText.focus.notFocus}}</option>
					</select>
				</div>
				<div class="flex-row">
					<div>{{lang.form.option.editText.long.name}}: </div>
					<select @change="changeLong">
						<option value="isLong" :selected="editText.isLong === true">{{lang.form.option.editText.long.isLong}}</option>
						<option value="notLong" :selected="editText.isLong === false">{{lang.form.option.editText.long.notLong}}</option>
					</select>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import { getGenealogy } from '../assist'
	export default {
		name: "EditText",
		props: ["editText", "setting", "targets", "enums", "lang"],
		components: {
		},
		data() {
			return {
				show_content: true,
			}
		},
		created() {
		},
		methods: {
			inputTitle: function(e) {
				var input = {
					action: "modify",
					content: {
						title: e.target.value,
					},
					record: false,
					genealogy: getGenealogy(this.editText)
				};
				this.$emit("dataOperation", input);
				this.$forceUpdate()
			},
			changeTitle: function(e) {
				var input = {
					action: "modify",
					content: {
						title: e.target.value,
					},
					record: true,
					genealogy: getGenealogy(this.editText)
				};
				this.$emit("dataOperation", input);
				this.$forceUpdate()
			},
			inputUnit: function(e) {
				var input = {
					action: "modify",
					content: {
						unit: e.target.value,
					},
					record: false,
					genealogy: getGenealogy(this.editText)
				};
				this.$emit("dataOperation", input);
			},
			changeUnit: function(e) {
				var input = {
					action: "modify",
					content: {
						unit: e.target.value,
					},
					record: true,
					genealogy: getGenealogy(this.editText)
				};
				this.$emit("dataOperation", input);
			},
			changeInputType: function(e) {
				var input = {
					action: "modify",
					content: {
						input_type: e.target.value,
					},
					record: true,
					genealogy: getGenealogy(this.editText)
				};
				this.$emit("dataOperation", input);
			},
			changeFocus: function(e) {
				var input = {
					action: "modify",
					content: {
						isFocus: e.target.value === "isFocus",
					},
					record: true,
					genealogy: getGenealogy(this.editText)
				};
				this.$emit("dataOperation", input);
			},
			changeLong: function(e) {
				var input = {
					action: "modify",
					content: {
						isLong: e.target.value === "isLong",
					},
					record: true,
					genealogy: getGenealogy(this.editText)
				};
				this.$emit("dataOperation", input);
			},
			showOrHiddenContent: function() {
				this.show_content = !this.show_content;
			},
			focus: function() {
				this.$emit('focus')
			},
			blur: function() {
				this.$emit('blur')
			},
		}
	}
</script>

<style scoped>
	.flex-row {
		margin: 5px 0 0;
	}
	.edit {
		width: 100%;
		padding: 0 1em;
	}
	.title {
		width: 30%;
		padding-right: 0.5em;
	}
	.input-text {
		line-height: 1em;
	}
	.content {
		flex: 1 1 70%;
	}
	input[type="text"] {
		font-size: 1em;
		width: 100%;
		text-align: center;
		border: none;
		color: #000;
		border-bottom: 2px solid #ccc;
	}
	.value input[type="text"] {
		width: 30%;
	}
	.value {
		margin: 0;
		height: 1.6em;
		width: 100%;
	}
	.setting .flex-row div {
		font-size: 0.8em;
		flex: 0 1 40%;
	}
	select {
		margin: 0 2px;
		font-size: 0.75em;
	}
	.text-blank {
		border-bottom: 1px solid #000;
		flex: 1 1;
		margin-right: 8px;
		text-align: center;
		min-width: 30%;
	}
	.controller {
		flex: 0 0 1em;
	}
	.hidden-btn {
		border: none;
		color: #777;
	}
	.warning {
		margin: 0;
		width: 100%;
		color: #a33;
		z-index: 99999;
		font-size: 0.9em;
		white-space: nowrap;
		text-overflow: ellipsis;
		overflow: hidden;
		word-wrap: break-word;
		word-break: break-all;
		overflow-wrap: break-word;
	}

</style>