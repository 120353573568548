<template>
	<div class="flex-row radio-group-wrapper" :style="getStyle()">
		<Title class="title" :title="radioGroup.title + '：'" :title_style="'text-align:left;left:0;'"/>
		<transition-group tag="div" name="transit-radioGroup" class="radio-group-items" :class="radioGroup.control.preview.enable ? '' : 'disable'">
			<button class="radio-group-item-btn" :class="radioGroup.control.preview.checked === index ? 'checked' : ''" :key="item.id" v-for="(item, index) in radioGroup.items" @click="toggle" :index.prop="index">
				{{item.name}}
			</button>
		</transition-group>
	</div>
</template>

<script>
	import Title from "./Title";
	import { getGenealogy } from '../assist';
	export default {
		name: "RadioGroup",
		props: ["radioGroup"],
		components: {
			Title,
		},
		created() {
		},
		methods: {
			toggle: function(e) {
				if(!this.radioGroup.control.preview.enable)
					return;
				let input = {
					content: {
						checked: e.target.index
					},
					genealogy: getGenealogy(this.radioGroup)
				}
				this.$emit("toggle", input);
			},
			getStyle() {
				if (!this.radioGroup.control.preview.visible) {
					return 'display: none;';
				}
				return '';
			}
		}
	}
</script>

<style scoped>
	.radio-group-wrapper {
		width: 100%;
		justify-content: space-between;
		margin-right: 8px;
	}
	.title {
		min-width: 40%;
	}
	.radio-group-items {
		flex: 1 1;
	}
	.radio-group-items .radio-group-item-btn {
		display: inline-block;
		float: left;
		width: 45%;
		height: 2em;
		margin: 2%;
		border: 2px solid #6d9bc6;
		border-radius: 4px;
		color: #6d9bc6;
	}
	.radio-group-items .radio-group-item-btn.checked {
		background-color: #6d9bc6;
		color: #faf7f2;
	}
	.radio-group-items.disable .radio-group-item-btn {
		border: 2px solid #aaa;
		background-color: #aaa;
		color: #faf7f2;
	}
	.transit-radioGroup-move {
  		transition: transform 1.5s;
	}
</style>