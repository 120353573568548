<template>
	<div class="flex-column radio">
		<div class="flex-row main">
		<div class="title">
			<input class="input-text" type="text" :value="selector.title" @input="inputTitle" @change="changeTitle" :placeholder="lang.form.title.title">
			<div class="warning" v-if="selector.duplicate_title" :title="lang.message.duplicate_title">*{{lang.message.duplicate_title}}</div>
			<div class="warning" v-if="selector.invalid_title" :title="lang.message.invalid_title">*{{lang.message.invalid_title}}</div>
		</div>
		<div class="flex-column controller">
			<button class="hidden-btn" @click="showOrHiddenContent"><i :class="show_content ? 'fas fa-caret-down' : 'fas fa-caret-right'"></i></button>
		</div>
		<div class="flex-column">
			<div class="flex-row setting">
				<AmountController :hint="lang.form.option.selector.amount" :count="selector.items.length" @add="addItem" @remove="removeLastItem" style="justify-content: flex-start;" />
			</div>
			<div class="flex-column content" v-if="show_content">
				<draggable v-bind="dragOptions" @end="dragend" handle=".handle">
					<template v-for="(item, index) in selector.items">
						<div :key="item.id" class="flex-row item">
							<i class="fa fa-align-justify handle"></i>
							<input class="input-text" type="text" @input="inputItem" @change="changeItem" :value="item.name" :index.prop="index" :placeholder="lang.form.option.selector.item" @focus="focus" @blur="blur">
							<button class="rule-btn" :class="open_rule[index] ? 'open' : 'close'" @click="openOrCloseRuleSetting" :index.prop="index">rule</button>
							<button class="close-btn px-3" @click="removeItem" :index.prop="index"></button>
						</div>
						<div :key="index" v-if="open_rule[index]">
							<!--Rule :rule="selector.rules.children[index]" :controller="selector" :ruleSetting="ruleSetting" @ruleOperation="ruleOperation"/-->
						</div>
					</template>
				</draggable>
			</div>
		</div>
		</div>
	</div>
</template>

<script>
	import AmountController from './AmountController';
	import { getGenealogy, deepCopy } from '../assist';
	import draggable from 'vuedraggable';
	export default {
		name: "Selector",
		props: ["selector", "setting", "targets", "enums", "lang"],
		components: {
			AmountController,
			draggable,
		},
		data() {
			return {
				open_rule: [],
				show_content: true,
			}
		},
		created() {
			if(this.selector.items.length > 5) {
				this.show_content = false
			}
		},
		computed: {
			dragOptions() {
				return {
					group: this.selector.items,
					disabled: false,
					chosenClass: "chosen", // 拖曳目標的class
					dragClass: "dragged", // 拖曳中的class（滑鼠上）
					forceFallback: true,
					animation: "300",
					scrollSensitivity: "100",
				};
			},
		},
		methods: {
			inputTitle: function(e) {
				let input = {
					action: "modify",
					content: {
						title: e.target.value,
					},
					record: false,
					genealogy: getGenealogy(this.selector)
				};
				this.$emit("dataOperation", input);
			},
			changeTitle: function(e) {
				
				let input = {
					action: "modify",
					content: {
						title: e.target.value,
					},
					record: true,
					genealogy: getGenealogy(this.selector)
				};
				this.$emit("dataOperation", input);
			},
			inputItem: function(e) {
				let items = deepCopy(this.selector.items);
				items[e.target.index]['name'] = e.target.value;
				let input = {
					action: "modify",
					content: {
						items: items,
					},
					record: false,
					genealogy: getGenealogy(this.selector)
				};
				this.$emit("dataOperation", input);
			},
			changeItem: function(e) {
				let items = deepCopy(this.selector.items);
				items[e.target.index]['name'] = e.target.value;
				let input = {
					action: "modify",
					content: {
						items: items,
					},
					record: true,
					genealogy: getGenealogy(this.selector)
				};
				this.$emit("dataOperation", input);
			},
			addItem: function(e) {
				let items = deepCopy(this.selector.items);
				items.push({ id: Math.random().toString(36).slice(2), name: "" });
				let input = {
					action: "modify",
					content: {
						items: items,
					},
					record: true,
					genealogy: getGenealogy(this.selector)
				};
				this.$emit("dataOperation", input);
			},
			removeLastItem: function(e) {
				if(this.selector.items.length <= 1)
					return;
				let items = deepCopy(this.selector.items);
				items.pop();
				var input = {
					action: "modify",
					content: {
						items: items,
					},
					record: true,
					genealogy: getGenealogy(this.selector)
				};
				this.$emit("dataOperation", input);
			},
			removeItem: function(e) {
				if(this.selector.items.length <= 1)
					return;
				let items = deepCopy(this.selector.items);
				let checked = this.selector.checked >= items.length || this.selector.checked === e.target.index ? -1 : this.selector.checked
				items.splice(e.target.index, 1)
				var input = {
					action: "modify",
					content: {
						items: items,
					},
					record: true,
					genealogy: getGenealogy(this.selector)
				};
				this.$emit("dataOperation", input);
			},
			openOrCloseRuleSetting: function(e) {
				this.open_rule[e.target.index] = !this.open_rule[e.target.index];
				this.$forceUpdate();
			},
			ruleOperation: function(input) {
				//input.parent.push({id: this.selector.id});
				this.$emit("dataOperation", input);
			},
			showOrHiddenContent: function() {
				this.show_content = !this.show_content;
			},
			dragstart: function(e) {
			},
			dragend: function(e) {
				let input = {
					action: "sort",
					content: {
						type: "selector",
						old: {
							index: e.oldIndex,
						},
						new: {
							index: e.newIndex,
						}
					},
					record: true,
					genealogy: getGenealogy(this.selector)
				};
				this.$emit("dataOperation", input);
			},
			focus: function() {
				this.$emit('focus')
			},
			blur: function() {
				this.$emit('blur')
			},
		}
	}
</script>

<style scoped>
	.main > div.flex-column {
		width: 70%;
	}
	.radio {
		width: 100%;
		padding: 0 1em;
		margin: 5px 0 0;
	}
	.title {
		width: 30%;
		margin-right: 0.5em;
	}
	.title:after {
		content: "";
	}
	.input-text {
		line-height: 1em;
	}
	input[type="text"] {
		font-size: 1em;
		width: 100%;
		text-align: center;
		border: none;
		color: #000;
		border-bottom: 2px solid #ccc;
	}
	.content{
		margin: 0;
		width: 100%;
		font-size: 0.9em;
	}
	.content .item {
		margin: 0 5px 2px;
		align-items: center;
		width: 100%;
	}
	.close-btn {
		margin-right: 0.5em;
		background: transparent;
		border: none;
		height: 3em;
		z-index: 99;
	}
	.close-btn:after {
		color: red;
		align-content: center;
		content: "\f00d";
		font-family: fontAwesome;
	}
	.close-btn:hover:after {
		color: darkred;
		font-size: 1.5em;
	}
	.check-btn{
		border: none;
		font-size: 0.5em;
	}
	.check-btn .selected{
		color: green;
	}
	.chosen {
		background-color: rgba(224, 224, 240, 0.6);
	}
	.dragged {
		background-color: rgba(224, 224, 240, 0.4);
	}
	.setting {
		font-size: 0.9em;
	}
	.setting * {
		font-size: 1em;
	}
	.setting i {
		font-size: 0.8em;
	}
	select {
		margin: 0 2px;
	}
	.expansion-btn {
		border: none;
		font-size: 1.5em;
		color: #555;
	}
	.text-blank {
		border-bottom: 1px solid #000;
		flex: 1 1;
		margin-right: 8px;
		text-align: center;
		min-width: 30%;
	}
	.rule-btn {
		display: none;
		font-size: 1em;
		font-weight: normal;
		border: 1px solid #ccc;
		border-radius: 1em;
		text-align: center;
		padding: 0 1em;
		margin-left: 1em;
	}
	.rule-btn.close:after {
		content: "\f105";
		font-family: fontAwesome;
		margin-left: 0.5em;
	}
	.rule-btn.open:after {
		content: "\f107";
		font-family: fontAwesome;
		margin-left: 0.5em;
	}
	.controller {
		flex: 0 0 1em;
	}
	.hidden-btn {
		border: none;
		color: #777;
	}
	.warning {
		margin: 0;
		width: 100%;
		color: #a33;
		z-index: 99999;
		word-wrap: break-word;
		white-space: nowrap;
		text-overflow: ellipsis;
		overflow: hidden;
		font-size: 0.9em;
	}
	.handle {
		float: left;
		padding-top: 8px;
		padding-bottom: 8px;
	}
</style>