<template>
	<div class="rule">
		<div class="row" style="justify-content: center;">
			<div class="px-3 col-12 col-lg-6" v-for="(action, action_index) in setting.rule.action" :key="action">
				<div class="flex-row">
					<!-- <div class="effect-title">{{lang.form.rule[action]}}</div> -->
					<AmountController :hint="lang.form.rule[action]" :count="rule.children[action_index].children.length" :minmax="true" ref="Controller" @add="addRuleItem(rule.children[action_index])" @remove="removeLastRuleItem(rule.children[action_index])" @addAll="addAllRuleItems(rule.children[action_index])" @removeAll="removeAllRuleItems(rule.		children[action_index])" />
				</div>
				<RuleItem :ruleItem="ruleitem" :controller="controller" :rule="rule" :ruleItems="rule.children[action_index]" :setting="setting" :targets="targets" :enums="enums" :lang="lang" v-for="ruleitem in rule.children[action_index].children" :key="ruleitem.id" @dataOperation="dataOperation" @focus="focus" @blur="blur"/>
			</div>
		</div>
	</div>
</template>

<script>//radioGroup, editText, biEditText), image
	import AmountController from './AmountController';
	import RuleItem from './RuleItem';
	import { getGenealogy, deepCopy } from '../assist';

	export default {
		name: "Rule",
		props: ["rule", "controller", "setting", "targets", "enums", "lang"],
		components: {
			AmountController,
			RuleItem
		},
		data() {
			return {
				record: true,
				expansion_delim: "",
			}
		},
		created() {
		},
		methods: {
			dataOperation: function(input) {
				this.$emit('dataOperation', input);
			},
			addRuleItem: function(ruleItems) {
				console.log('addRuleItem');
				if(ruleItems.children.length >= this.targets.length)
					return;
				let input = {
					action: "add",
					content: {
						object_type: this.enums.ObjectType.ITEM,
						item_type: this.enums.ItemType.RULE_ITEM,
					},
					record: true,
					genealogy: getGenealogy(ruleItems)
				};
				this.$emit("dataOperation", input);
			},
			addAllRuleItems: function(ruleItems) {
				if(this.rule.children.map(o=>o.children).flat().length >= this.targets.length)
					return;
				let rule_targets = this.targets.filter((target) => {
					return target.id!==this.controller.id && this.rule.children.map(o=>o.children).flat().findIndex(o=>o.target && o.target.id===target.id) == -1
				})
				ruleItems.children.forEach((item, index) => {
					if(!item.target) {
						let input = {
							action: "remove",
							content: {
								id: item.id,
							},
							record: true,
							genealogy: getGenealogy(ruleItems)
						};
						this.$emit("dataOperation", input);
					}
				})
				rule_targets.forEach((target, index) => {
					let input = {
						action: "add",
						content: {
							object_type: this.enums.ObjectType.ITEM,
							item_type: this.enums.ItemType.RULE_ITEM,
						},
						record: false,
						genealogy: getGenealogy(ruleItems)
					};
					this.$emit("dataOperation", input);
					input = {
						action: "binding",
						content: {
							target: getGenealogy(target)
						},
						record: index === rule_targets.length - 1,
						genealogy: getGenealogy(ruleItems.children[ruleItems.children.length - 1])
					};
					this.$emit("dataOperation", input);
				})
			},
			removeLastRuleItem: function(ruleItems) {
				if(ruleItems.children.length <= 0)
					return;
				let input = {
					action: "remove",
					content: {
						id: ruleItems.children[ruleItems.children.length - 1].id,
					},
					record: true,
					genealogy: getGenealogy(ruleItems)
				};
				this.$emit("dataOperation", input);
			},
			removeAllRuleItems: function(ruleItems) {
				let count = ruleItems.children.length
				for(let i = 0; i < count; i++) {
					let input = {
						action: "remove",
						content: {
							id: ruleItems.children[0].id,
						},
						record: i === count - 1,
						genealogy: getGenealogy(ruleItems)
					};
					this.$emit("dataOperation", input);
				}
			},
			focus: function() {
				this.$emit('focus')
			},
			blur: function() {
				this.$emit('blur')
			},
		}
	}
</script>

<style scoped>
	.rule {
		margin: 0 2em;
		width: 100%;
		font-size: 0.9em;
	}
	.effect-title {
		width: 30%;
		margin-right: 0.5em;
	}
	.effect-title:after {
		content: ":";
	}
	.add-all-btn, .remove-all-btn {
		margin: 1px 5px;
		padding: 0 3px;
		border-radius: 1em;
	}
	.rule-item-list {
		margin-right: 24px;
		border: 1px solid #ccc;
		padding: 6px 12px;
		border-radius: 12px;
	}
	.rule-item{
		justify-content: space-between;
		align-items: center;
	}
	.rule-item > div {
		align-items: center;
		justify-content: flex-start;
	}
	.rule-item-title {
		width: 100%;
		height: 1.5em;
	}
	.rule-item-content {
		justify-content: flex-start;
		align-items: center;
		width: 40%;
		margin-left: 5px;
	}
	.rule-item-content .key {
		width: 1.5em;
		font-size: 0.8em;
	}
	.rule-item .icon {
		margin-left: 5px;
	}
	.rule-item .icon.radioGroup:after{
		content: "\f00c";
		font-family: fontAwesome;
		color: #aaa;
	}
	.rule-item .icon.checkBoxes:after{
		content: "\f00c";
		font-family: fontAwesome;
		color: #aaa;
	}
	.rule-item .icon.editText:after{
		content: "\f303";
		font-family: fontAwesome;
		color: #aaa;
	}
	.rule-item .icon.image:after{
		content: "\f03e";
		font-family: fontAwesome;
		color: #aaa;
	}
	.rule-item-content .value {
		width: 100%;
	}
	.rule-item-content .value * {
		width: 100%;
	}
	input[type="text"] {
		text-align: center;
		border: none;
		color: #000;
		border-bottom: 2px solid #ccc;
	}
	.close-btn {
		margin-right: 0.5em;
		background: transparent;
		border: none;
		height: 3em;
		z-index: 99;
	}
	.close-btn:after {
		color: red;
		align-content: center;
		content: "\f00d";
		font-family: fontAwesome;
	}
	.close-btn:hover:after {
		color: darkred;
		font-size: 1.5em;
	}

</style>