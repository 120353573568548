<template>
	<div class="list-wrapper" :class="infoItems.list_type">
		<div class="flex-row">
			<div class="title">({{infoItems.index+1}})</div>
			<div class="flex-row" style="font-size:0.8em; height:min-content; flex:1 1; justify-self:center;">
				<AmountController class="info-amount" :hint="lang.form.image.info.amount" :count="infoItems.children.length" @add="addInfoItem" @remove="removeInfoItem" style="justify-content: flex-start;" />
			</div>
		</div>
        <!-- {{infoItems}} -->
		<InfoItem v-for="infoItem in infoItems.children" :key="infoItem.id" :name="infoItem.id" :infoItem="infoItem" :controller="controller" :setting="setting" :targets="targets" :lang="lang" :enums="enums" @dataOperation="dataOperation" @focus="focus" @blur="blur" />
	</div>
</template>

<script>
	import InfoItem from './InfoItem';
	import AmountController from './AmountController';
	import { getGenealogy } from '../assist';
	export default {
		name: "InfoItems",
		props: ["infoItems", "controller", "setting", "targets", "enums", "lang"],
		components: {
			InfoItem,
			AmountController,
		},
		methods: {
			dataOperation: function(input) {
				this.$emit('dataOperation', input);
			},
			addInfoItem: function() {
				let input = {
					action: "add",
					content: {
						object_type: this.enums.ObjectType.ITEM,
						item_type: this.enums.ItemType.INFO_ITEM,
					},
					record: true,
					genealogy: getGenealogy(this.infoItems)
				};
				this.$emit("dataOperation", input);
			},
			removeInfoItem: function() {
				if(this.infoItems.children.length <= 0)
					return;
				let input = {
					action: "remove",
					content: {
						id: this.infoItems.children[this.infoItems.children.length - 1].id,
					},
					record: true,
					genealogy: getGenealogy(this.infoItems)
				};
				this.$emit("dataOperation", input);
			},
			focus: function() {
				this.$emit('focus')
			},
			blur: function() {
				this.$emit('blur')
			}
		}
	}
</script>

<style scoped>
	.list-wrapper > div {
		background-color: rgba(0,0,0,0);
	}
	.images {
		background-color: rgba(0,0,0,0);
		border: 2px solid #354E84;
		border-radius: 12px;
		margin: 6px 12px 12px;
		min-height: 50px;
		font-size: 16px;
	}
	.close-btn {
		background-color: rgba(0,0,0,0);
		margin-left: 0.5em;
		border: none;
		height: 3em;
		position: absolute;
		z-index: 99;
	}
	.close-btn:after {
		color: red;
		align-content: center;
		content: "\f00d";
		font-family: fontAwesome;
	}
	.close-btn:hover:after {
		color: darkred;
		font-size: 1.5em;
	}
	.options {
		border: none;
	}
	
</style>