<template>
    <div class="flex-row info-content">
        <!-- <pre style="white-space:pre-wrap">{{info}}</pre> -->
        <input class="input-text" type="text" :value="infoItem.prefix" @input="inputInfoPrefix" @change="changeInfoPrefix" :placeholder="lang.form.image.info.prefix" @focus="focus" @blur="blur">
        <select @change="changeInfoTarget">
            <option :value="-1" style="display:none"></option>
            <option :value="item_key" :selected="infoItem.target && infoItem.target.id === item.id" v-for="(item, item_key) in info_targets" :key="item_key">{{item.name}}</option>
        </select>
        <input class="input-text" type="text" :value="infoItem.suffix" @input="inputInfoSuffix" @change="changeInfoSuffix" :placeholder="lang.form.image.info.suffix" @focus="focus" @blur="blur">
        <button class="close-btn" @click="removeInfoItem"></button>
    </div>
</template>

<script>
	import AmountController from "./AmountController"
	import { getGenealogy } from '../assist';
	export default {
		name: "InfoItem",
		components: {
			AmountController,
		},
		props: ["infoItem", "controller", "setting", "enums", "targets", "lang"],
		data() {
			return {
				info_targets: []
			}
		},
		created() {
			this.info_targets = this.getInfoTargets
		},
		watch: {
			targets: {
				deep: true,
				handler() {
					this.info_targets = this.getInfoTargets
					this.$forceUpdate()
				}
			}
		},
		computed: {
			getInfoTargets() {
				return this.targets.filter((target) => {
					return target.id!==this.controller.id// && this.rule.children.filter(o=>o.id!==this.ruleItems.id).map(o=>o.children).flat().findIndex(o=>o.target && o.target.id===target.id) == -1
				})
			}
		},
		methods: {
			removeInfoItem: function() {
				let input = {
					action: "remove",
					content: {
						id: this.infoItem.id,
					},
					record: true,
					genealogy: this.infoItem.ancestor_id
				};
				this.$emit("dataOperation", input);
			},
			changeInfoTarget: function(e) {
				let input = {
					action: "binding",
					content: {
                        target: e.target.value >= 0 ? getGenealogy(this.info_targets[e.target.value]) : undefined
					},
					record: true,
					genealogy: getGenealogy(this.infoItem)
				};
				this.$emit("dataOperation", input);
			},
			inputInfoPrefix: function(e) {
				let input = {
					action: "modify",
					content: {
						prefix: e.target.value,
					},
					record: false,
					genealogy: getGenealogy(this.infoItem)
				};
				this.$emit("dataOperation", input);
			},
			changeInfoPrefix: function(e) {
				let input = {
					action: "modify",
					content: {
						prefix: e.target.value,
					},
					record: true,
					genealogy: getGenealogy(this.infoItem)
				};
				this.$emit("dataOperation", input);
			},
			inputInfoSuffix: function(e) {
				let input = {
					action: "modify",
					content: {
						suffix: e.target.value,
					},
					record: true,
					genealogy: getGenealogy(this.infoItem)
				};
				this.$emit("dataOperation", input);
			},
			changeInfoSuffix: function(e) {
				let input = {
					action: "modify",
					content: {
						suffix: e.target.value,
					},
					record: true,
					genealogy: getGenealogy(this.infoItem)
				};
				this.$emit("dataOperation", input);
			},
			focus: function() {
				this.$emit('focus')
			},
			blur: function() {
				this.$emit('blur')
			}
		}
	}
</script>

<style scoped>
	.input-text {
		line-height: 1em;
	}
	input[type="text"] {
		font-size: 1em;
		width: 100%;
		text-align: center;
		border: none;
		color: #000;
		border-bottom: 2px solid #ccc;
		background-color: transparent;
	}
	.close-btn {
		margin: 0 0.5em;
		background: transparent;
		border: none;
		height: 3em;
		z-index: 99;
	}
	.close-btn:after {
		color: red;
		align-content: center;
		content: "\f00d";
		font-family: fontAwesome;
	}
	.close-btn:hover:after {
		color: darkred;
		font-size: 1.5em;
	}
	.info-amount {
		margin: 0 auto;
	}
	.info > div.flex-row {
		flex: 1 1;
		align-items: center;
		background-color: transparent;
		justify-content: flex-start;
	}
	.info-list-wrapper {
		flex: 1 1;
		/* border: 2px solid #354E84; */
		/* border-radius: 12px; */
		background-color: transparent;
		margin: 6px 12px 12px;
		padding: 6px 0;
		border-bottom: .5px solid #aaa;
	}
	.info-list-wrapper:last-child {
		border: none
	}
	.info-list-wrapper > * {
		background-color: transparent;
		margin-left: 24px;
	}
	.info-list-wrapper > *:first-child {
		margin-left: auto;
	}
	.info-control, .info-content {
		font-size: 0.8em;
	}
	.info-control {
		height: min-content;
	}
	.info-content > * {
		margin: .25rem;
		background-color: transparent;
		height: min-content;
	}
</style>