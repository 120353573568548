<template>
	<div class="section-wrapper">
		<Title :title="section.title" :title_style="'text-align:left;left:0;font-weight: 500;'"/>
		<div v-for="(child, key) in section.children" :key="key">
			<Item v-if="child.object_type == 'item'" :item="child" :enums="enums" @toggle="toggle" />
			<List v-else-if="child.object_type == 'list'" :list="child" :enums="enums" @toggle="toggle" />
		</div>
	</div>
</template>

<script>
	import Title from './Title';
	import Item from './Item';
	import List from './List';
	export default {
		name: "Section",
		props: ["section", "enums"],
		components: {
			Title,
			Item,
			List,
		},
		methods: {
			toggle: function(input) {
				this.$emit("toggle", input);
			},
		}
	}
</script>

<style scoped>
	.section-wrapper {
		background-color: #fafafa;
		border: 2px solid #354E84;
		border-radius: 8px;
		min-height: 1.2em;
		padding: 4px 6px;
		margin-bottom: 8px;
		font-size: 0.75em;
	}
</style>