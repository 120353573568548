export const Common = {
	methods: {
		isEmpty: function(obj) {
			// null and undefined are "empty"
			if (obj == null || typeof obj === "undefined") return true;

			// Assume if it has a length property with a non-zero value
			// that that property is correct.
			if (obj.length > 0)    return false;
			if (obj.length === 0)  return true;

			// If it isn't an object at this point
			// it is empty, but it can't be anything *but* empty
			// Is it empty?  Depends on your application.
			if (typeof obj !== "object") return true;

			// Otherwise, does it have any properties of its own?
			// Note that this doesn't handle
			// toString and valueOf enumeration bugs in IE < 9
			var hasOwnProperty = Object.prototype.hasOwnProperty;
			for (var key in obj) {
				if (hasOwnProperty.call(obj, key)) return false;
			}

			return true;
		},
		sortArray: function(arr, key, reverse=1) {
			arr.sort(function (a, b) {
				var swap = a[key] > b[key] ? 1 : -1;
				return reverse * swap;
			});
		},
		deleteItem: function(obj, key) {
			if (typeof obj !== "object")
				return;
			if (Array.isArray(obj)) {
				obj.splice(key, 1);
			}
			else {
				delete obj[key];
			}
		}
	}
}