<template>
	<div class="list-wrapper" :class="list.list_type">
		<div v-if="list.list_type===enums.ListType.IMAGES">
			<Title :title="lang.form.image.name" :placeholder="lang.form.title.title" :readonly="true" />
			<AmountController :hint="lang.form.image.amount" :count="list.children.length" @add="addImage" @remove="removeImage" />
			<button class="close-btn" @click="removeImages"></button>
		</div>
		<div v-else-if="list.list_type===enums.ListType.INFO_ITEMS" class="flex-row">
			<div class="title">({{list.index+1}})</div>
			<div class="flex-row" style="font-size:0.8em; height:min-content; flex:1 1; justify-self:center;">
				<AmountController class="info-amount" :hint="lang.form.image.info.amount" :count="list.children.length" @add="addInfoItem" @remove="removeInfoItem" style="justify-content: flex-start;" />
			</div>
		</div>
		<draggable v-bind="dragOptions" @start="dragstart" @end="dragend" handle=".handle">
			<template v-for="child in list.children">
				<!-- <i :key="child.id" class="fa fa-align-justify handle"></i> -->
				<Item :key="child.id" :class="checkDrag(child)" :name="child.id" v-if="child.object_type == enums.ObjectType.ITEM" :item="child" :setting="setting" :targets="targets" :lang="lang" :enums="enums" @dataOperation="dataOperation" @focus="focus" @blur="blur" />
				<List :key="child.id" :name="child.id" v-if="child.object_type == enums.ObjectType.LIST" :item="child" :setting="setting" :targets="targets" :lang="lang" :enums="enums" @dataOperation="dataOperation" @focus="focus" @blur="blur" />
			</template>
		</draggable>
	</div>
</template>

<script>
	import Title from './Title';
	import AmountController from './AmountController';
	import { getGenealogy } from '../assist';
	import draggable from 'vuedraggable';
	export default {
		name: "List",
		props: ["list", "setting", "targets", "enums", "lang"],
		components: {
			Item: () => import('./Item.vue'),
			AmountController,
			Title,
			List: () => import('./List.vue'),
			draggable
		},
		data(){
			return {
				isDragging: false,
			}
		},
		computed: {
			dragOptions() {
				return {
					group: this.list.children,
					disabled: this.disableDrag,
					chosenClass: "chosen", // 拖曳目標的class
					dragClass: "dragged", // 拖曳中的class（滑鼠上）
					forceFallback: true,
					draggable: ".dragItem",
					animation: "500",
					scrollSensitivity: "100",
				};
			},
			disableDrag() {
				for (const item of this.$parent.$children) {
					if(item.title && item.title === "備註"){
						return true;
					}
				}
				return false;
			},
		},
		watch: {
			isDragging(dragging){
				// console.log(dragging);
			}
		},
		methods: {
			checkDrag(item){
				if(item.section_type && item.section_type === 'noteSection'){
					return '';
				}
				return 'dragItem';
			},
			dataOperation: function(input) {
				this.$emit('dataOperation', input);
			},
			dragstart: function(e) {
				this.isDragging = true;
				// let section = this.list.children[e.oldIndex];
				// if(section.section_type && section.section_type === 'noteSection'){
				// 	this.draggable = false;
				// }
				// this.draggable = true;
			},
			dragend: function(e) {
				let input = {
					action: "sort",
					content: {
						// section: this.list.children[e.oldIndex],
						type: "list",
						old: {
							index: e.oldIndex,
						},
						new: {
							index: e.newIndex,
						}
					},
					record: true,
					genealogy: getGenealogy(this.list)
				};
				this.$emit("dataOperation", input);
				this.isDragging = false;
			},
			addImage: function() {
				let input = {
					action: "add",
					content: {
						object_type: this.enums.ObjectType.ITEM,
						item_type: this.enums.ItemType.IMAGE,
						type: this.enums.ImageType.IMAGE
					},
					record: true,
					genealogy: getGenealogy(this.list)
				};
				this.$emit("dataOperation", input);
			},
			removeImage: function() {
				if(this.list.children.length <= 0)
					return;
				let input = {
					action: "remove",
					content: {
						id: this.list.children[this.list.children.length - 1].id,
					},
					record: true,
					genealogy: getGenealogy(this.list)
				};
				this.$emit("dataOperation", input);
			},
			removeImages: function() {
				var input = {
					action: "remove",
					content: {
						id: this.list.id,
					},
					record: true,
					genealogy: this.list.ancestor_id
				};
				this.$emit("dataOperation", input);
			},
			addInfoItem: function() {
				let input = {
					action: "add",
					content: {
						object_type: this.enums.ObjectType.ITEM,
						item_type: this.enums.ItemType.INFO_ITEM,
					},
					record: true,
					genealogy: getGenealogy(this.list)
				};
				this.$emit("dataOperation", input);
			},
			removeInfoItem: function() {
				if(this.list.children.length <= 0)
					return;
				let input = {
					action: "remove",
					content: {
						id: this.list.children[this.list.children.length - 1].id,
					},
					record: true,
					genealogy: getGenealogy(this.list)
				};
				this.$emit("dataOperation", input);
			},
			focus: function() {
				this.$emit('focus')
			},
			blur: function() {
				this.$emit('blur')
			}
		}
	}
</script>

<style scoped>
	.list-wrapper > div {
		background-color: rgba(0,0,0,0);
	}
	.images {
		background-color: rgba(0,0,0,0);
		border: 2px solid #354E84;
		border-radius: 12px;
		margin: 6px 12px 12px;
		min-height: 50px;
		font-size: 16px;
	}
	.close-btn {
		background-color: rgba(0,0,0,0);
		margin-left: 0.5em;
		border: none;
		height: 3em;
		position: absolute;
		z-index: 99;
	}
	.close-btn:after {
		color: red;
		align-content: center;
		content: "\f00d";
		font-family: fontAwesome;
	}
	.close-btn:hover:after {
		color: darkred;
		font-size: 1.5em;
	}
	.options {
		border: none;
	}
	.chosen {
		background-color: rgba(224, 224, 240, 0.6);
	}
	.dragged {
		background-color: rgba(224, 224, 240, 0.4);
	}
	.handle {
		float: right;
		padding-top: 8px;
		padding-bottom: 8px;
	}
</style>