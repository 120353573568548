<template>
	<div class="list-wrapper">
		<button class="camera-btn" v-if="list.list_type=='images'">拍照</button>
		<transition-group name="transit-list" tag="div" :class="list.list_type">
			<template v-for="child in list.children" >
				<Item :key="child.id" :name="child.id" v-if="child.object_type == 'item'" :item="child" :enums="enums" @toggle="toggle"/>
			</template>
		</transition-group>
	</div>
</template>

<script>
	export default {
		name: "List",
		props: ["list", "enums"],
		components: {
			Item: () => import('./Item.vue'),
		},
		methods: {
			toggle: function(input) {
				this.$emit("toggle", input);
			},
		}
	}
</script>

<style scoped>
	* {
		background: transparent;
	}
	.list-wrapper, .list-wrapper .sections, .list-wrapper .sections .list-item {
		/* background-color: #d5dde6; */
		text-align: left;
	}
	.list-wrapper .images {
		display: flex;
		justify-content: flex-start;
		overflow-y: hidden;
		background-color: #fafafa;
		border-radius: 4px;
		text-align: left;
	}
	.list-wrapper .images::-webkit-scrollbar { height: 0 !important } chrome, safari
	.list-wrapper .images { -ms-overflow-style: none; } /*IE 10+*/
	.list-wrapper .images { overflow: -moz-scrollbars-none; } /* Firefox*/
	.camera-btn {
		background-color: #2488c6;
		border-radius: 8px;
		color: #faf7f2;
		padding: 0.25rem .5rem;
		user-select: none;
		margin: 4px 0;
		font-size: 0.25em;
	}
	.transit-list-move {
  		transition: transform 1.5s;
	}
</style>