<template>
	<div class="title" :style="title_style">
		{{title}}
	</div>
</template>

<script>
	export default {
		name: "Title",
		props: ["title", "title_style"],
		components: {
		},
		methods: {
		}
	}
</script>

<style scoped>
	.title{
		/*border: 1px solid blue;*/
		background-color: rgba(0,0,0,0);
		font-size: 1.5em;
		/*text-align: center;*/
		margin-bottom: 0.5em;
	}
</style>