<template>
	<div id="FormCreator">
		<div class="form">
			<Title :title="form.title" :placeholder="lang.form.title.headline" @input="inputTitle" @change="changeTitle"/>
			<div v-if="typeof form.children !== 'undefined'">
				<AmountController :hint="lang.form.section.amount" :count="form.children[1].children.length + 1" @add="addSection" @remove="removeSection" />
			</div>
			<div v-for="(child, key) in form.children" :key="key">
				<Item v-if="child.object_type == enums.ObjectType.ITEM" :item="child" :setting="setting" :targets="targets" :enums="enums" :lang="lang" @dataOperation="dataOperation" />
				<List v-else-if="child.object_type == enums.ObjectType.LIST" :list="child" :setting="setting" :targets="targets" :enums="enums" :lang="lang" @dataOperation="dataOperation" />
			</div>
		</div>
	</div>
</template>

<script>
import Title from './form/Title';
import Item from './form/Item';
import List from './form/List';
import AmountController from './form/AmountController';
import { getGenealogy } from './assist';

export default {
	name: 'app',
	props: ["form", "setting", "targets", "enums", "lang"],
	components: {
		Title,
		Item,
		List,
		AmountController,
	},
	methods: {
		dataOperation: function(input) {
			this.$emit('dataOperation', input);
		},
		inputTitle: function(e) {
			let input = {
				action: "modify",
				content: {
					title: e,
				},
				record: false,
				genealogy: getGenealogy(this.form)
			};
			this.$emit("dataOperation", input);
		},
		changeTitle: function(e) {
			let input = {
				action: "modify",
				content: {
					title: e,
				},
				record: true,
				genealogy: getGenealogy(this.form)
			};
			this.$emit("dataOperation", input);
		},
		addSection: function() {
			let sections = this.form.children.find(o => o.list_type == "sections")
			let input = {
				action: "add",
				content: {
					object_type: this.enums.ObjectType.ITEM,
					item_type: this.enums.ItemType.SECTION,
					type: this.enums.SectionType.NORMAL_SECTION
				},
				record: true,
				genealogy: getGenealogy(sections)
			};
			this.$emit("dataOperation", input);
		},
		removeSection: function() {
			let sections = this.form.children.find(o => o.list_type == this.enums.ListType.SECTIONS)
			let index = sections.children.length - 1;
			if(index < 0)
				return;
			if(sections.children[index].section_type === this.enums.SectionType.NOTE_SECTION) {
				index -= 1;
				if(index < 0)
					return;
			}
			var input = {
				action: "remove",
				content: {
					id: sections.children[index].id,
				},
				record: true,
				genealogy: getGenealogy(sections)
			};
			this.$emit("dataOperation", input);
		},
	}
}
</script>

<style scoped>
	#FormCreator {
	}
	.form {
		border: 2px solid #354E84;
		border-radius: 12px;
		background-color: transparent;
		font-size: 20px;
		min-width: 540px;
	}
	.form *{
		background-color: transparent;
	}
</style>