import uuid from 'uuid';
//import Common from '../../common-methods.js'

export default {
	methods: {
		initData: function() {
			var data = this.newObject("data");
			/*var data = Object.assign({}, data, {
				id: uuid.v4(),
				type: 'data',
				content: {},
				children: [],
				parent: [],
			});*/
			return data;
		},
		newObject: function(type, parent=[]) {
			var obj = {};
			obj = Object.assign({}, obj, {
				id: uuid.v4(),
				type: type,
				title: '',
				visiable: true,
				index: 0,
				content: {},
				children: [],
				parent: parent,
			});
			return obj;
		},
		addCourse: function(parent, obj={}) {
			var course = this.newObject("course", parent);
			course.content.introduction = "";
			for(var key in obj) {
				if(key in course) {
					course[key] = obj[key];
				}
				else if(key === "unit" && Array.isArray(obj.unit)) {
					for (var i = 0; i < obj.unit.length; i++) {
						obj.unit[i].index = i;
						var unit = this.addUnit(this.getInfo(course), obj.unit[i]);
						course.children.push(unit);
					}
				}
				else {
					course.content[key] = obj[key];
				}
			}
			return course;
		},
		addUnit: function(parent, obj={}) {
			var unit = this.newObject("unit", parent);
			unit.content.introduction = "";
			for(var key in obj) {
				if(key in unit) {
					unit[key] = obj[key];
				}
				else if(key === "form" && Array.isArray(obj.form)) {
					for (var i = 0; i < obj.form.length; i++) {
						var form = this.addForm(this.getInfo(unit), obj.form[i]);
						unit.children.push(form);
					}
				}
				else {
					unit.content[key] = obj[key];
				}
			}
			return unit;
		},
		addForm: function(parent, obj={}) {
			var form = this.newObject("form", parent);
			for(var key in obj) {
				if(key in form) {
					form[key] = obj[key];
				}
				form.content[key] = obj[key];
			}
			return form;
		},
		readJson: function(obj) {
			var data = this.initData();
			if("course" in obj && Array.isArray(obj.course)) {
				for (var i = 0; i < obj.course.length; i++) {
					obj.course[i].index = i;
					var course = this.addCourse(this.getInfo(data), obj.course[i]);
					data.children.push(course);
				}
			}
			return data;
		},
		getJson: function(data) {
			if(this.isEmpty(data)) {
				data = this.initData();
			}
			var json = this.getObjectJson(data);
			return json.content;
		},
		getListJson: function(name, list) {
			var json = {name: name, content: []};
			for (var i = 0; i < list.length; i++) {
				json.content.push(JSON.parse(JSON.stringify(this.getObjectJson(list[i]).content)));
			}
			return json;
		},
		getObjectJson: function(obj) {
			var json = {name: obj.type, content: {}};
			var list = {};
			switch(obj.type) {
				case "data":
					list = this.getListJson("course", obj.children);
					json.content[list.name] = JSON.parse(JSON.stringify(list.content));
					break;
				case "course":
					list = this.getListJson("unit", obj.children);
					json.content.title = obj.title;
					json.content[list.name] = JSON.parse(JSON.stringify(list.content));
					break;
				case "unit":
					list = this.getListJson("form", obj.children);
					json.content.title = obj.title;
					json.content[list.name] = JSON.parse(JSON.stringify(list.content));
					break;
				case "form":
					json.content = obj.content;
					break;
			}
			for(var key in obj.content) {
				json.content[key] = JSON.parse(JSON.stringify(obj.content[key]));
			}
			return json;
		},
		isJsonValid: function(type, obj) {
			var result = false;
			switch(type) {
				case "home":
					result =  "course" in obj;
					break;
				case "course":
					result = "unit" in obj;
					break;
				case "unit":
					result = "form" in obj;
					break;
				case "form":
					result = "basic" in obj && "sections" in obj;
					break;
			}
			if(!result) {
				alert("json格式錯誤");
			}
			return result;
		},
		isDataValid: function(obj) {
			return "course" in obj;
		},
		isCourseValid: function(obj) {
			return "unit" in obj;
		},
		isUnitValid: function(obj) {
			return "form" in obj;
		},
		isFormValid: function(obj) {
			return "basic" in obj && "sections" in obj;
		},
		dataOperation: function(data, input) {
			var obj = this.findDescendant(data, input.parent);
			switch(input.type) {
				case "modify":
					for (var key in input.content) {
						if(key in obj) {
							if (key === "content") {
								for(var k in input.content[key]) {
									if (k in obj[key]) {
										obj[key][k] = input.content[key][k];
									}
								}
							}
							else {
								obj[key] = input.content[key];
							}
						}
					}
					break;
				case "add":
					switch(input.content.type) {
						case "course":
							obj.children.push(this.addCourse(this.getInfo(obj)));
							break;
						case "unit":
							obj.children.push(this.addUnit(this.getInfo(obj)));
							break;
						case "form":
							obj.children.push(this.addForm(this.getInfo(obj)));
							break;
					}
					break;
				case "remove":
					this.deleteItem(obj.children, obj.children.findIndex(o => o.id==input.content.id));
					//this.deleteItem(obj.children, input.content.index);
					break;
				case "sort":
					break;
				default:
					break;
			}
		},
		getInfo: function(obj) {
			var parent = [{id: obj.id}];
			for (var i = 0; i < obj.parent.length; i++) {
				parent.push({id: obj.parent[i].id});
			}
			return parent;
		},
		findItem: function(list, cond) {
			for (var i = 0; i < list.length; i++) {
				var result = true;
				for(var key in cond) {
					result = result && (list[i][key] === cond[key]);
					if(!result)
						break;
				}
				if(result)
					return list[i];
			}
			return undefined;
		},
		findChild: function(parent, cond) {
			return this.findItem(parent.children, cond);
		},
		findDescendant: function(data, genealogy) {
			var obj = data;
			for (var i = genealogy.length - 2; i >= 0; i--) {
				obj = this.findChild(obj, genealogy[i]);
				if(typeof obj === "undefined") 
					break;
			}
			return obj;
		},
	}
}