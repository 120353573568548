<template>
	<div class="option-wrapper" :class="option.control.preview.enable ? '' : 'disable'">
		<div v-if="option.type==='biEditText'" :class="option.type">
			<BiEditText :biEditText="option" :enums="enums"/>
		</div>
		<div v-else-if="option.type==='editText'" :class="option.type">
			<EditText :editText="option" :enums="enums"/>
		</div>
		<div v-else-if="option.type==='radioGroup'" :class="option.type">
			<RadioGroup :radioGroup="option" :enums="enums" @toggle="toggle"/>
		</div>
		<div v-else-if="option.type==='checkBoxes'" :class="option.type">
			<CheckBoxes :checkBoxes="option" :enums="enums" @toggle="toggle"/>
		</div>
		<div v-else-if="option.type==='selector'" :class="option.type">
			<Selector :selector="option" :enums="enums" @toggle="toggle"/>
		</div>
		<div v-else-if="option.type==='note'" :class="option.type">
			<Note :note="option" :enums="enums"/>
		</div>
		<div class="child" :class="child.type" v-for="(child, key) in option.children" :key="key">
			<Option v-if="child.item_type=='option'" :option="child" :enums="enums"/>
		</div>
	</div>
</template>

<script>
	import EditText from './EditText';
	import BiEditText from './BiEditText';
	import RadioGroup from './RadioGroup';
	import CheckBoxes from './CheckBoxes';
	import Selector from './Selector';
	import Note from './Note';
	import Option from './Option';
	
	export default {
		name: "Option",
		props: ["option", "enums"],
		components: {
			EditText,
			BiEditText,
			RadioGroup,
			CheckBoxes,
			Selector,
			Note,
			Option
		},
		methods: {
			toggle: function(input) {
				this.$emit("toggle", input);
			}
		},
		created() {
		}
	}
</script>

<style scoped>
	.option-wrapper {
		padding: 0 4px;
		font-size: 0.9em;
	}
	.child {
		font-size: 0.9em;
		color: #666;
		margin-left: 50%;
	}
	.disable {
		/*display: none;*/
	}
</style>