<template>
	<div class="tab-page">
		<div id="Tab" class="tab-btn-list">
			<button @click="changeTab" class="tab-btn" :class="this.show === 'phone' ? 'active' : ''" :value.prop="'phone'">{{data.language[data.language.lang].preview.phone}}</button>
			<button @click="changeTab" class="tab-btn" :class="this.show === 'json' ? 'active' : ''" :value.prop="'json'">{{data.language[data.language.lang].preview.json}}</button>
			<!-- <button @click="changeTab" class="tab-btn" :class="this.show === 'data' ? 'active' : ''" :value.prop="'data'">Data</button> -->
		</div>
		<div class="tab-inner">
			<Preview v-if="this.show === 'phone'" :data="data" :enums="enums" @toggle="toggle"/>
			<vue-json-pretty id="JsonViewer" v-else-if="this.show === 'json'" :path="'res'" :data="data.json"></vue-json-pretty>
			<vue-json-pretty id="JsonViewer" v-if="this.show === 'data' && data.form !== {}" :path="'res'" :data="data.form"></vue-json-pretty>
		</div>
	</div>
</template>
<script>
	import VueJsonPretty from 'vue-json-pretty';
	import Preview from './Preview';
	export default {
		name: "Tab",
		components: {
			VueJsonPretty,
			Preview,
		},
		props: ["data", "enums"],
		data() {
			return {
				show: 'phone'
			}
		},
		methods: {
			changeTab: function(e) {
				this.show = e.target.value;
			},
			toggle: function(input) {
				this.$emit("toggle", input)
			}
		}
	};
</script>

<style>
:root {
	--tab-count: 2;
}
.tab-page {
	height: 100%;
	user-select: none;
}
#Tab button.tab-btn {
	outline: none;
	width: calc(100% / var(--tab-count) - 1%);
	width: -moz-calc(100% / var(--tab-count) - 1%);
	width: -webkit-calc(100% / var(--tab-count) - 1%);
	min-width: 4em;
	font-size: 20px;
	margin-bottom: -10px;
}
#Tab button.tab-btn.active {
	width: calc(100% / var(--tab-count) + (var(--tab-count) - 1) * 1%);
	width: -moz-calc(100% / var(--tab-count) + (var(--tab-count) - 1) * 1%);
	width: -webkit-calc(100% / var(--tab-count) + (var(--tab-count) - 1) * 1%);
	font-size: 25px;
	border-bottom: none;
	border-radius: 5px 5px 0 0;
	z-index: 999;
}
#Tab button.tab-btn:first-child, #Tab button.tab-btn.active:first-child {
	border-top-left-radius: 15px;
}
#Tab button.tab-btn:not(:last-child) {
	border-right: none;
}
#Tab button.tab-btn:last-child, #Tab button.tab-btn.active:last-child {
	border-top-right-radius: 15px;
}
.tab-inner {
	padding: 1rem;
	padding-top: 1.5rem;
	margin-top: -2px;
	border: 1px solid #ccc;
	border-top: none;
	height: calc(100% - 28px);
	height: -moz-calc(100% - 28px);
	height: -webkit-calc(100% - 28px);
	overflow-y: auto;
}
#JsonViewer {
	text-align: left;
}
</style>
