import uuid from 'uuid';
import { getGenealogy, isEmpty, sortArray, deleteItem, setObject, deepCopy, isEqual, arrayMove } from './assist'

export default {
	data() {
		return {
			expansion_delim: "_",
			setting: {
				option: {
					normal: ["editText", "biEditText", "radioGroup", "checkBoxes", "selector"],
					expand: ["editText"],
				},
				rule: {
					option: ["radioGroup"],
					json_key: ["onSelect"],
					toggle: ["select"],
					value_key: ["checked"],
					action: ["enable", "disable", "visible", "hidden"],
					effect: { radioGroup: {checked: -1}, editText: {value: ''} }
				},
				drawing: {
					mode: ["none", "threePitch", "fourPitch", "pipelineGraph", "designGraph"],
					// type: ["single", "unlimited", "dirX", "dirY", "three", "four"],
					type: {
						threePitch: ["single", "unlimited", "three", "singleObject"],
						fourPitch: ["single", "dirX", "dirY", "four"],
						pipelineGraph: ["three", "four"]
					},
					info_amount: {
						"none.*": 0, "*.single": 1, "*.three": 3, "*.four": 4, 
						"threePitch.unlimited": 3, "fourPitch.dirX": 1, "fourPitch.dirY": 1
					}
				},
				invalid_chars: ['_']
			},
			enums: {
				ObjectType: { FORM: 'form', LIST: 'list', ITEM: 'item' },
				ListType: {
					SECTIONS: 'sections', OPTIONS: 'options', IMAGES: 'images', RULES: 'rules', RULE_ITEMS: 'ruleItems', INFOS: 'infos', INFO_ITEMS: 'infoItems'
				},
				ItemType: {
					SECTION: 'section', OPTION: 'option', IMAGE: 'image', RULE: 'rule', RULE_ITEM: 'ruleItem', INFO_ITEM: 'infoItem'
				},
				SectionType: {
					BASIC_SECTION: 'basicSection', NORMAL_SECTION: 'normalSection', NOTE_SECTION: 'noteSection'
				},
				OptionType: {
					EDIT_TEXT: 'editText', BI_EDIT_TEXT: 'biEditText', RADIO_GROUP: 'radioGroup', CHECK_BOXES: 'checkBoxes', SELECTOR: 'selector', NOTE: 'note'
				},
				ImageType: {
					IMAGE: 'image'
				}
			},
			record_max_step: 20,
			language: {
				lang: "cht",
				list: {
					eng: "English",
					cht: "繁體中文",
				},
				eng: {
					nav: {
						upload: "Upload",
						download: "Download",
						setting: "Setting",
						language: "Language",
						redo: "Redo",
						undo: "Undo",
						save: "Save",
						note: "Note",
						image: "Image",
					},
					form: {
						title: {
							headline: "Headline",
							title: "Title",
						},
						section: {
							name: "Section",
							amount: "Section"
						},
						option: {
							name: "Option",
							amount: "Option",
							expansion: "Expansion",
							editText: {
								name: "EditText",
								value: "Value",
								unit: "Unit",
								type: {
									name: "Type",
									text: "Text",
									number: "Number",
								},
								focus: {
									name: "Focus",
									isFocus: "IsFocus",
									notFocus: "NotFocus",
								},
								long: {
									name: "Long",
									isLong: "Long",
									notLong: "Short",
								}
							},
							biEditText: {
								name: "BiEditText"
							},
							radioGroup: {
								name: "RadioGroup",
								item: "Item",
								amount: "Item"
							},
							checkBoxes: {
								name: "CheckBoxes",
								item: "Item",
								amount: "Item"
							},
							selector: {
								name: "Selector",
								item: "Item",
								amount: "Item"
							},
							note: {
								name: "Note",
								hint: "Hint"
							},
						},
						rule: {
							name: "Rule",
							enable: "Enable",
							disable: "Disable",
							visible: "Visible",
							hidden: "Hidden",
						},
						image: {
							name: "Image",
							amount: "Image",
							drawingMode: {
								name: "Drawing Mode",
								none: "None",
								threePitch: "Three Pitch",
								fourPitch: "Four Pitch",
								pipelineGraph: "Pipeline Graph",
								designGraph: "Design Graph"
							},
							drawingType: {
								name: "Drawing Type",
								single: "Single",
								unlimited: "Unlimited",
								dirX: "Direction X",
								dirY: "Direction Y",
								three: "Three Pitch",
								four: "Four Pitch",
								singleObject: "Single Object",
							},
							info: {
								name: "Info",
								amount: "Info",
								prefix: "Prefix",
								suffix: "Suffix",
							},
						}
					},
					preview: {
						phone: "Phone",
						json: "Json"
					},
					message: {
						duplicate_title: "Duplicate title.",
						invalid_title: "Invalid characters in title: '_'.",
						save_successfully: "Save successfully!",
						failed_duplicate_title: "Duplicate titles!",
						failed_unknown_target: "There are unknown targets!",
					}
				},
				cht: {
					nav: {
						upload: "上傳檔案",
						download: "下載檔案",
						setting: "介面設定",
						language: "語言設定",
						redo: "復原",
						undo: "重做",
						save: "儲存",
						note: "備註",
						image: "圖片",
					},
					form: {
						title: {
							headline: "大標題",
							title: "標題",
						},
						section: {
							name: "區塊",
							title: "標題",
							amount: "區塊數量"
						},
						option: {
							name: "項目",
							title: "標題",
							amount: "項目數量",
							expansion: "附加",
							editText: {
								name: "文字方塊",
								value: "使用者輸入",
								unit: "單位",
								type: {
									name: "輸入類型",
									text: "文字",
									number: "數字",
								},
								focus: {
									name: "可編輯",
									isFocus: "是",
									notFocus: "否",
								},
								long: {
									name: "輸入框長度",
									isLong: "長",
									notLong: "短",
								}
							},
							biEditText: {
								name: "雙欄式文字"
							},
							radioGroup: {
								name: "單選按鈕",
								item: "選項",
								amount: "選項數量"
							},
							checkBoxes: {
								name: "多選按鈕",
								item: "選項",
								amount: "選項數量"
							},
							selector: {
								name: "下拉式選單",
								item: "選項",
								amount: "選項數量"
							},
							note: {
								name: "備註",
								hint: "提示訊息"
							},
						},
						rule: {
							name: "規則",
							enable: "啟用",
							disable: "鎖定",
							visible: "顯示",
							hidden: "隱藏"
						},
						image: {
							name: "圖片",
							amount: "圖片數量",
							drawingMode: {
								name: "繪圖模式",
								none: "無需繪圖",
								threePitch: "三支距",
								fourPitch: "四支距",
								pipelineGraph: "管線圖",
								designGraph: "設計圖"
							},
							drawingType: {
								name: "繪圖形式",
								single: "單一支距",
								unlimited: "不限數量支距",
								dirX: "X向支距",
								dirY: "Y向支距",
								three: "三支距",
								four: "四支距",
								singleObject: "單一物件",
							},
							info: {
								name: "標注資訊",
								amount: "標注數量",
								prefix: "前綴",
								suffix: "後綴",
							}
						}
					},
					preview: {
						phone: "手機預覽",
						json: "資料內容"
					},
					message: {
						duplicate_title: "此標題已存在。",
						invalid_title: "標題請勿使用此字元：‘_’",
						save_successfully: "儲存成功!",
						failed_duplicate_title: "含有重複標題的項目!",
						failed_unknown_target: "含有無法辨識的控制項!",
					}
				}
			},
		}
	},
	/*
	objectType: [form, list, item]
	listType: [sections, options, images, rules, ruleItems, infos, infoItems]
	itemType: [section, option, image, rule, ruleItem, infoItem]
	sectionType: [basicSection, normalSection, noteSection]
	optionType: [editText, biEditText, radioGroup, selector, note]
	

	Object:
		id: uuid.v4()
		object_type: // objectType
		ancestor_id: []
		index: number
		children: []
		content: {}
		
		Form:
			title
			// child: list, item

		List:
			list_type: // listType
			json_key
			// child: item

			Rules
				controller_id

			RuleItems
				action: enable, disable

			Infos
			InfoItems

		Item:
			item_type: // itemType

			Section:
				section_type: // sectionType
				title: ''
				control: {
					errors: []
					preview: {}
					readonly: T/F
				}

				BasicSection
					json_key

			Option:
				type: // optionType
				title: ''
				control: {
					errors: []
					preview: {}
					readonly: T/F
				}
				isExpand: T/F
				bindings: []

				EditText
					name: // section.title_option.title
					input_type
					value
					unit
					isFocus
					isLong
					// child: option
				BiEditText
					name: // section.title_option.title
					editTexts
					// child: option
				RadioGroup
					name: // section.title_option.title
					checked
					items
					// child: option
				Selector
					name: // section.title_option.title
					items
					// child: option
				Note
					hint

			Image:
				type: "image"
				name: // section.title_option.title
				title: ''
				control: {
					errors: []
					preview: {}
					readonly: T/F
				}
				drawingMode
				drawingType
				bindings: []
				// child: Infos

			Rule:
				toggle: {
					select:
				}

			RuleItem
				target
				effect: {
					checked:
					value:
				}
			
				
			InfoItem
				target
				info: {
					prefix
					suffix
				}


	*/
	methods: {
		// Initial Form
		init: function() {
			var creator = {};
			creator = Object.assign({}, creator, {
				form: {},
				items: {
					newest: true,
					targets: [],
					unknownTargets: [],
					duplicateTitleObjects: [],
					errorItems: []
				},
				record: {
					steps: [],
					now_step: -1,
					can_redo: false,
					can_undo: false,
					can_save: false,
				},
				json: {},
				ready: false,
				language: this.language
			});
			creator.form = this.initForm(creator);
			return creator;
		},
		initForm: function(creator) {
			creator.items.newest = true
			creator.items.targets.splice(0, creator.items.targets.length)
			creator.items.unknownTargets.splice(0, creator.items.unknownTargets.length)
			creator.items.errorItems.splice(0, creator.items.errorItems.length)
			let form = this.newObject();
			form.title = ''
			form.object_type = this.enums.ObjectType.FORM;
			form.control = { errors: {}, preview: { enable: true, visible: true }, readonly: false }
			form.children.push(this.addSection(creator, this.getGenealogy(form), "basicSection"));
			form.children.push(this.addList(creator, this.getGenealogy(form), "sections"));
			creator.form = form;
			return creator.form;
		},

		newObject: function(ancestor=[]) {
			let obj = {
				id: uuid.v4(),
				object_type: "",
				ancestor_id: ancestor,
				index: 0,
				children: [],
				content: {}
			}
			obj = Object.assign({}, obj, obj)
			return obj;
		},
		newItem: function(ancestor) {
			let item = this.newObject(ancestor);
			item.object_type = this.enums.ObjectType.ITEM
			item.item_type = ''
			item = Object.assign({}, item, item)
			return item;
		},
		newList: function(ancestor) {
			let list = this.newObject(ancestor);
			list.object_type = this.enums.ObjectType.LIST
			list.json_key = ''
			list = Object.assign({}, list, list)
			return list;
		},

		addList: function(creator, ancestor, type, data=[]) {
			//sections, options, images, rules, ruleItems, infos, infoItems
			let list = this.newList(ancestor)
			list.list_type = type
			switch(type) {
				case this.enums.ListType.SECTIONS:
					list.json_key = "sections"
					for(let i in data) {
						if(data[i].title == "備註")
							list.children.push(this.addSection(creator, this.getGenealogy(list), this.enums.SectionType.NOTE_SECTION, data[i]))
						else {
							list.children.push(this.addSection(creator, this.getGenealogy(list), this.enums.SectionType.NORMAL_SECTION, data[i]))
							list.children[i].index = Number.parseInt(i)
						}
					}
					break;
				case this.enums.ListType.OPTIONS:
					list.json_key = "options"
					for(let i in data) {
						list.children.push(this.addOption(creator, this.getGenealogy(list), data[i].type, data[i]))
						list.children[i].index = Number.parseInt(i)
					}
					break;
				case this.enums.ListType.IMAGES:
					list.json_key = "images"
					for(let i in data) {
						list.children.push(this.addImage(creator, this.getGenealogy(list), "image", data[i]))
						list.children[i].index = Number.parseInt(i)
					}
					break;
				case this.enums.ListType.RULES:
					list.json_key = "onSelect"
					for(let i in data) {
						let toggle = {}
						for(let key in data[i]) {
							if(this.setting.rule.toggle.includes(key)) {
								toggle[key] = data[i][key]
							}
						}
						list.children.push(this.addRule(creator, this.getGenealogy(list), toggle, data[i]))
						list.children[i].index = Number.parseInt(i)
					}
					break;
				case this.enums.ListType.RULE_ITEMS:
					list.json_key = ""
					for(let i in data) {
						list.children.push(this.addRuleItem(creator, this.getGenealogy(list), data[i]))
						list.children[i].index = Number.parseInt(i)
					}
					break;
				case this.enums.ListType.INFOS:
					list.json_key = "info"
					for(let i in data) {
						list.children.push(this.addList(creator, this.getGenealogy(list), this.enums.ListType.INFO_ITEMS, data[i]))
						list.children[i].index = Number.parseInt(i)
					}
					break;
				case this.enums.ListType.INFO_ITEMS:
					for(let i in data) {
						list.children.push(this.addInfoItem(creator, this.getGenealogy(list), data[i]))
						list.children[i].index = Number.parseInt(i)
					}
					break;
			}
			list = Object.assign({}, list, list)
			return list
		},
		addSection: function(creator, ancestor, type, data={}) {
			let section = this.newItem(ancestor)
			section.item_type = this.enums.ItemType.SECTION
			section.section_type = type
			section.title = ""
			section.control = { errors: {}, preview: { enable: true }, readonly: false }
			this.setObjectValue(section, data)
			if(type === this.enums.SectionType.NOTE_SECTION) {
				section.children.push(this.addList(creator, this.getGenealogy(section), this.enums.ListType.OPTIONS))
				section.children[0].children.push(this.addOption(creator, this.getGenealogy(section.children[0]), this.enums.OptionType.NOTE))
				for(let i in data.options) {
					if(data.options[i].type === this.enums.OptionType.NOTE) {
						section.children[0].children[0].hint = data.options[i].hint
					}
				}
				section.control.readonly = true
				section.index = 99
			}
			else {
				section.children.push(this.addList(creator, this.getGenealogy(section), this.enums.ListType.OPTIONS, data.options))
			}
			if(type === this.enums.SectionType.BASIC_SECTION) {
				section.json_key = "basic"
			}
			section = Object.assign({}, section, section)
			return section
		},
		addOption: function(creator, ancestor, type, data={}) {
			// console.log(data);
			let option = this.newItem(ancestor)
			option.item_type = this.enums.ItemType.OPTION
			option.type = type
			option.title = ""
			option.control = { errors: {}, preview: { enable: true, visible: true }, readonly: false }
			option.isExpand = !!data.isExpand
			option.bindings = []
			if(data.id) {
				option.id = data.id
				deleteItem(data, 'id')
			}
			switch(type) {
				case this.enums.OptionType.EDIT_TEXT:
					option = Object.assign({}, option, {
						name: "",
						input_type: "text",
						value: "",
						unit: "",
						isFocus: true,
						isLong: true,
					});
					option.control.preview.value = ""
					if(data.objects) {
						this.setObjectValue(option, data.objects[0])
					}
					else
						this.setObjectValue(option, data)
					option.control.preview.value = option.value
					break;
				case this.enums.OptionType.BI_EDIT_TEXT:
					option = Object.assign({}, option, {
						editTexts: []
					});
					option.editTexts.push(this.addOption(creator, this.getGenealogy(option), this.enums.OptionType.EDIT_TEXT, {index: 0, isExpand: option.isExpand}))
					option.editTexts.push(this.addOption(creator, this.getGenealogy(option), this.enums.OptionType.EDIT_TEXT, {index: 1, isExpand: option.isExpand}))
					if(data.objects) {
						this.setObjectValue(option.editTexts[0], data.objects[0])
						this.setObjectValue(option.editTexts[1], data.objects[1])
					}
					else {
						this.setObjectValue(option, data)
					}
					break;
				case this.enums.OptionType.RADIO_GROUP:
					option = Object.assign({}, option, {
						name: "",
						checked: -1,
						items: [
							{ id: Math.random().toString(36).slice(2), name: "" },
							{ id: Math.random().toString(36).slice(2), name: "" },
						]
					});
					// option.items.push("")
					// option.items.push("")
					option.control.preview.checked = -1
					this.setObjectValue(option, data)
					option.control.preview.checked = option.checked
					break;
				case this.enums.OptionType.CHECK_BOXES:
					option = Object.assign({}, option, {
						name: "",
						checked: [],
						items: [
							{ id: Math.random().toString(36).slice(2), name: "" },
							{ id: Math.random().toString(36).slice(2), name: "" },
						]
					});
					// option.items.push("")
					// option.items.push("")
					option.control.preview.checked = -1
					this.setObjectValue(option, data)
					option.control.preview.checked = option.checked
					break;
				case this.enums.OptionType.SELECTOR:
					option = Object.assign({}, option, {
						name: "",
						checked: -1,
						items: [
							{ id: Math.random().toString(36).slice(2), name: "" }
						],
					});
					// option.items.push("")
					option.control.preview.checked = -1
					this.setObjectValue(option, data)
					option.control.preview.checked = option.checked
					break;
				case this.enums.OptionType.NOTE:
					option = Object.assign({}, option, {
						hint: ''
					});
					this.setObjectValue(option, data)
					break;
			}
			if(this.setting.rule.option.includes(option.type)) {
				let index = this.setting.rule.option.indexOf(option.type)
				if(data && data[this.setting.rule.json_key[index]])
					option.rules = this.addList(creator, this.getGenealogy(option), this.enums.ListType.RULES, data[this.setting.rule.json_key[index]])
				else {
					let rule_data = [];
					for(let i in option.items) {
						let toggle = {}
						toggle[this.setting.rule.toggle[index]] = Number.parseInt(i)
						rule_data.push(toggle)
					}
					option.rules = this.addList(creator, this.getGenealogy(option), this.enums.ListType.RULES, rule_data)
				}
				option.rules.json_key = this.setting.rule.json_key[index]
			}
			if(option.isExpand) 
				option.json_key = "expansion"
			if(data.expansion) {
				let ex_data = { ...data.expansion, isExpand: true, index: option.children.length }
				option.children.push(this.addOption(creator, this.getGenealogy(option), this.enums.OptionType.EDIT_TEXT, ex_data))
			}
			this.setObjectName(creator, option)
			option = Object.assign({}, option, option)
			return option
		},
		addImage: function(creator, ancestor, type, data={}) {
			let image = this.newItem(ancestor)
			image.item_type = this.enums.ItemType.IMAGE
			image.type = type
			image.title = ""
			image.drawingMode = "none"
			image.drawingType = null
			image.control = { errors: {}, preview: { enable: true }, readonly: false }
			image.bindings = []
			this.setObjectValue(image, data)
			image.infos = this.addList(creator, this.getGenealogy(image), this.enums.ListType.INFOS, data.info)
			this.setObjectName(creator, image)
			image = Object.assign({}, image, image)
			return image
		},
		setObjectName: function(creator, option) {
			const hasNameObjects = [this.enums.OptionType.EDIT_TEXT, this.enums.OptionType.BI_EDIT_TEXT, this.enums.OptionType.RADIO_GROUP, this.enums.OptionType.CHECK_BOXES, this.enums.OptionType.SELECTOR, this.enums.ImageType.IMAGE]
			if(!hasNameObjects.includes(option.type))
				return
			if(option.type === this.enums.ImageType.IMAGE) {
				option.name = option.title
			}
			else {
				let section = this.findDescendant(creator, option.ancestor_id, {item_type: this.enums.ItemType.SECTION})
				if(section) {
					let name = `${section.title}_`
					if(option.isExpand) {
						let parent = this.findDescendant(creator, option.ancestor_id)
						if(parent.type === this.enums.OptionType.BI_EDIT_TEXT) {
							for(let i in option.editTexts)
								name += `${parent.editTexts[i].title}_`
						}
						else
							name += `${parent.title}_`
					}
					if(option.type === this.enums.OptionType.BI_EDIT_TEXT) {
						for(let i in option.editTexts) {
							option.editTexts[i].name = `${name}${option.editTexts[i].title}`
						}
					}
					else
						option.name = `${name}${option.title}`
				}
			}
		},

		addRule: function(creator, ancestor, toggle, data={}) {
			let rule = this.newItem(ancestor)
			rule.item_type = this.enums.ItemType.RULE
			rule.toggle = deepCopy(toggle)
			this.setObjectValue(rule, data)
			for(let action_index in this.setting.rule.action) {
				let action = this.setting.rule.action[action_index]
				let items = this.addList(creator, this.getGenealogy(rule), this.enums.ListType.RULE_ITEMS, data[action])
				items = Object.assign({}, items, {json_key: action})
				rule.children.push(items)
			}
			return rule
		},
		addRuleItem: function(creator, ancestor, data={}) {
			let ruleItem = this.newItem(ancestor)
			ruleItem.item_type = this.enums.ItemType.RULE_ITEM
			let target_cond = {}
			if(data.object_name)
				target_cond = {name: data.object_name}
			else
				target_cond = {title: data.item, type: data.type}
			let target = this.findTarget(creator, target_cond)
			ruleItem.target = target
			if(typeof target === 'undefined') {
				let content = typeof data.type !== 'undefined' ? Object.keys(data).reduce((obj, key) => {
					if(this.setting.rule.effect[data.type] && Object.keys(this.setting.rule.effect[data.type]).includes(key))
						obj[key] = data[key]
					return obj
				}, {}) : 
				Object.keys(data).reduce((obj, key) => {
					if(Object.keys(this.setting.rule.effect).includes(key))
						obj[key] = data[key]
					return obj
				}, {})
				creator.items.unknownTargets.push({
					binding: this.getGenealogy(ruleItem),
					target: target_cond,
					content: content
				})
			}
			else {
				ruleItem.target = {
					id: target.id,
					name: target.name,
					title: target.title,
					type: target.type,
					ancestor_id: target.ancestor_id,
				}
				target.bindings.push(this.getGenealogy(ruleItem))
				if(target.type in this.setting.rule.effect) {
					ruleItem.content = Object.keys(data).reduce((obj, key) => {
						if(this.setting.rule.effect[target.type] && Object.keys(this.setting.rule.effect[target.type]).includes(key))
							obj[key] = data[key]
						return obj
					}, {})
					// deepCopy(this.setting.rule.effect[target.type])
				}
			}
			this.setObjectValue(ruleItem, data)
			return ruleItem
		},
		addInfoItem: function(creator, ancestor, data={}) {
			let infoItem = this.newItem(ancestor)
			infoItem.item_type = 'infoItem'
			infoItem.prefix = ''
			infoItem.suffix = ''
			let target = {}
			if(data.valueKey)
				target = {name: data.valueKey}
			else if(data.object_name)
				target = {name: data.object_name}
			else
				target = {title: data.item, type: data.type}
			infoItem.target = this.findTarget(creator, target)
			if(typeof infoItem.target === 'undefined') {
				creator.items.unknownTargets.push({
					binding: this.getGenealogy(infoItem),
					target: target
				})
			}
			else {
				infoItem.target = {
					id: target.id,
					name: target.name,
					title: target.title,
					type: target.type,
					ancestor_id: target.ancestor_id,
				}
				target.bindings.push(this.getGenealogy(infoItem))
			}
			this.setObjectValue(infoItem, data)
			infoItem = Object.assign({}, infoItem, infoItem)
			return infoItem
		},




		setObjectValue: function(obj, data) {
			for(let key in data) {
				if(key in obj) {
					if(Array.isArray(data[key])) {
						obj[key].splice(0, obj[key].length)// = []
						if(typeof data[key][0] === 'string' && obj[key] !== null) {
							for(let i in data[key]) {
								obj[key].push({ id: Math.random().toString(36).slice(2), name: "" })
								obj[key][obj[key].length - 1]['name'] = data[key][i];
							}
						} else if (typeof data[key][0] === 'object' && obj[key] !== null) {
							for(let i in data[key]) {
								obj[key].push(data[key][i])
							}
						}
					}
					else if(typeof obj[key] === 'object' && obj[key] !== null) {
						for(let i in data[key]) {
							obj[key][i] = data[key][i]
						}
					}
					else {
						obj[key] = data[key]
					}
					// setObject(obj, key, data[key])
				}
				else if(key in obj.content) {
					if(typeof obj.content[key] === 'object' && obj.content[key] !== null) {
						for(let i in data[key]) {
							obj.content[key][i] = data[key][i]
						}
					}
					else{
						obj.content[key] = data[key]
					}
				}
			}
			obj = Object.assign({}, obj, obj)
		},
		checkObjectValue: function(creator, obj) {
			switch(obj.object_type) {
				case this.enums.ObjectType.LIST:
					switch(obj.list_type) {
						// sections, options, images, rules, ruleItems, infos, infoItems
						case this.enums.ListType.SECTIONS:
						case this.enums.ListType.OPTIONS:
						case this.enums.ListType.IMAGES:
							break;
						case this.enums.ListType.RULES:
						case this.enums.ListType.RULE_ITEMS:
						case this.enums.ListType.INFOS:
						case this.enums.ListType.INFO_ITEMS:
							break;
					}
					break;
				case this.enums.ObjectType.ITEM:
					switch(obj.item_type) {
						case this.enums.ItemType.SECTION:
							break;
						case this.enums.ItemType.OPTION:
							this.checkOptionValue(creator, obj);
							break;
						case this.enums.ItemType.IMAGE:
							this.checkImageValue(creator, obj);
							break;
					}
					break;
			}
		},
		checkOptionValue: function(creator, option) {
			switch(option.type) {
				case this.enums.OptionType.EDIT_TEXT:
					option.control.preview.value = ""
					break;
				case this.enums.OptionType.BI_EDIT_TEXT:
					if(option.editTexts.length < 2) {
						option.editTexts.push(this.addOption(creator, this.getGenealogy(option), this.enums.OptionType.EDIT_TEXT, {index: 0}))
						option.editTexts.push(this.addOption(creator, this.getGenealogy(option), this.enums.OptionType.EDIT_TEXT, {index: 1}))
					}
					this.setObjectValue(option.editTexts[0], {
						name: option.name,
						title: option.title,
					})
					if(typeof option.input_type !== 'undefined' && typeof option.value !== 'undefined' && typeof option.unit !== 'undefined' && typeof option.isFocus !== 'undefined' && typeof option.isLong !== 'undefined')
						this.setObjectValue(option.editTexts[0], {
							index: 0,
							name: option.name,
							title: option.title,
							input_type: option.input_type,
							value: option.value,
							unit: option.unit,
							isFocus: option.isFocus,
							isLong: option.isLong,
						})
					break;
				case this.enums.OptionType.RADIO_GROUP:
					while(option.items.length < 2) {
						option.items.push("")
					}
					option.control.preview.checked = option.checked
					break;
				case this.enums.OptionType.CHECK_BOXES:
					while(option.items.length < 2) {
						option.items.push("")
					}
					option.control.preview.checked = option.checked
					break;
				case this.enums.OptionType.SELECTOR:
					option.control.preview.checked = option.checked
					break;
				case this.enums.OptionType.NOTE:
					// option.hint = ""
					break;
			}
			if(this.setting.rule.option.includes(option.type)) {
				let index = this.setting.rule.option.indexOf(option.type)
				while(option.rules.children.length < option.items.length) {
					let toggle = {}
					toggle[this.setting.rule.toggle[index]] = Number.parseInt(option.rules.children.length)
					option.rules.children.push(this.addRule(creator, this.getGenealogy(option.rules), toggle, {index: option.rules.children.length}))
					// option.rules.children[option.rules.children.length - 1].index = Number.parseInt(i)
				}
				while(option.rules.children.length > option.items.length) {
					option.rules.children.pop()
				}
			}
			this.setObjectName(creator, option)
			option = Object.assign({}, option, option)
		},
		checkImageValue: function(creator, image) {
			let max_amount = -1
			if(`${image.drawingMode}.${image.drawingType}` in this.setting.drawing.info_amount) {
				max_amount = this.setting.drawing.info_amount[`${image.drawingMode}.${image.drawingType}`]
			}
			else if(`*.${image.drawingType}` in this.setting.drawing.info_amount) {
				max_amount = this.setting.drawing.info_amount[`*.${image.drawingType}`]
			}
			else if(`${image.drawingMode}.*` in this.setting.drawing.info_amount) {
				max_amount = this.setting.drawing.info_amount[`${image.drawingMode}.*`]
			}
			if(max_amount >= 0) {
				while(image.infos.children.length < max_amount) {
					image.infos.children.push(this.addList(creator, this.getGenealogy(image.infos), this.enums.ListType.INFO_ITEMS))
					image.infos.children[image.infos.children.length - 1].index = Number.parseInt(image.infos.children.length - 1)
				}
				while(image.infos.children.length > max_amount) {
					image.infos.children.pop()
				}
			}
		},
		changeOptionType: function(creator, option) {
			let new_option = this.addOption(creator, option.genealogy, option.type, option)
			new_option.id = option.id
			return new_option
		},




		readJson: function(creator, obj) {
			this.initForm(creator);
			if(isEmpty(obj)) {
				return creator;
			}
			// console.log(obj)
			creator.items.newest = false
			let form = creator.form;
			form.title = obj.title;
			let basic = this.find(form.children, {section_type: this.enums.SectionType.BASIC_SECTION})
			this.setObjectValue(basic, obj.basic)
			let options = this.find(basic.children, {list_type: this.enums.ListType.OPTIONS})
			obj.basic.index = 0
			for(let i in obj.basic.options) {
				options.children.push(this.addOption(creator, this.getGenealogy(options), obj.basic.options[i].type, obj.basic.options[i]))
				options.children[i].index = Number.parseInt(i)
			}
			let sections = this.find(form.children, {list_type: this.enums.ListType.SECTIONS})
			for(let i in obj.sections) {
				if(obj.sections[i].title == "備註")
					sections.children.push(this.addSection(creator, this.getGenealogy(sections), this.enums.SectionType.NOTE_SECTION, obj.sections[i]))
				else {
					sections.children.push(this.addSection(creator, this.getGenealogy(sections), this.enums.SectionType.NORMAL_SECTION, obj.sections[i]))
					sections.children[i].index = Number.parseInt(i)
				}
			}
			sections.index = 1
			if(!isEmpty(obj.images)) {
				form.children.push(this.addList(creator, this.getGenealogy(form), "images", obj.images))
				form.children[form.children.length - 1].index = 99
			}
			this.checkForm(creator)
			// console.log(creator.form)
			this.getJson(creator);
			// console.log(creator.json)
			creator = Object.assign({}, creator, creator)
			return ;
		},
		getJson: function(creator) {
			let form = creator.form;
			if(this.isEmpty(form)) {
				form = this.initForm(creator);
			}
			let json = {};
		
			json.title = form.title;
			for(let i in form.children) {
				json = {...json, ...this.getObjectJson(form.children[i])}
				// let child_json = this.getObjectJson(form.children[i]);
				// json[child_json.json_key] = JSON.parse(JSON.stringify(child_json.content));
			}
			creator.json = JSON.parse(JSON.stringify(json));
			return json;
		},
		getObjectJson: function(obj) {
			switch(obj.object_type) {
				case this.enums.ObjectType.LIST:
					return this.getListJson(obj)
				case this.enums.ObjectType.ITEM:
					return this.getItemJson(obj)
			}
		},
		getListJson: function(obj) {
			let json = [];
			for(let i in obj.children) {
				json.push(this.getObjectJson(obj.children[i]));
			}
			if(obj.json_key) {
				let res = {}
				res[obj.json_key] = json
				return res
			}
			return json;
		},
		getItemJson: function(obj) {
			let json = {};
			switch(obj.item_type) {
				case this.enums.ItemType.SECTION:
					json.title = obj.title;
					break;
				case this.enums.ItemType.OPTION:
					json = this.getOptionJson(obj);
					break;
				case this.enums.ItemType.IMAGE:
					json.title = obj.title;
					json.drawingMode = obj.drawingMode;
					json.drawingType = obj.drawingType;
					if(!isEmpty(obj.infos))
						json = {...json, ...this.getObjectJson(obj.infos)}
					break;
				case this.enums.ItemType.RULE:
					json = {...json, ...obj.toggle}
					break;
				case this.enums.ItemType.RULE_ITEM:
					json.object_name = obj.target ? obj.target.name : '';
					json.type = obj.target ? obj.target.type : '';
					json.item = obj.target ? obj.target.title : '';
					break;
				case this.enums.ItemType.INFO_ITEM:
					json.valueKey = obj.target ? obj.target.name : '';
					json.prefix = obj.prefix;
					json.suffix = obj.suffix;
					break;
			}
			json = {...json, ...obj.content}
			for(let i in obj.children) {
				let child = this.getObjectJson(obj.children[i])
				for(let j in child) {
					if(typeof json[j] == 'undefined')
						json[j] = child[j]
				}
				// json = {...json, ...this.getObjectJson(obj.children[i])}
			}
			if(obj.json_key) {
				let res = {}
				res[obj.json_key] = json
				return res
			}
			return json;
		},
		getOptionJson: function(obj) {
			let json = {};
			// editText, biEditText, radioGroup, selector, note
			json.type = obj.type
			switch(obj.type) {
				case this.enums.OptionType.EDIT_TEXT:{
					json.objects = []
					let text = {}
					text.title = obj.title;
					text.input_type = obj.input_type;
					text.value = obj.value;
					text.unit = obj.unit;
					text.isFocus = obj.isFocus;
					text.isLong = obj.isLong;
					text.object_name = obj.name;
					json.objects.push(text)
					break;
				}
				case this.enums.OptionType.BI_EDIT_TEXT:
					json.objects = []
					for(let i in obj.editTexts) {
						let text = {}
						text.title = obj.editTexts[i].title;
						text.input_type = obj.editTexts[i].input_type;
						text.value = obj.editTexts[i].value;
						text.unit = obj.editTexts[i].unit;
						text.isFocus = obj.editTexts[i].isFocus;
						text.isLong = obj.editTexts[i].isLong;
						text.object_name = obj.editTexts[i].name;
						json.objects.push(text)
					}
					break;
				case this.enums.OptionType.RADIO_GROUP:
					json.title = obj.title;
					json.checked = obj.checked;
					json.items = obj.items.map(item => item.name);
					json.object_name = obj.name;
					break;
				case this.enums.OptionType.CHECK_BOXES:
					json.title = obj.title;
					json.checked = obj.checked;
					json.items = obj.items.map(item => item.name);
					json.object_name = obj.name;
					break;
				case this.enums.OptionType.SELECTOR:
					json.title = obj.title;
					json.items = obj.items.map(item => item.name);
					json.object_name = obj.name;
					break;
				case this.enums.OptionType.NOTE:
					json.hint = obj.hint;
					break;
			}
			if(!isEmpty(obj.rules))
				json = {...json, ...this.getObjectJson(obj.rules)}
			for(let i in obj.children) {
				// json = {...json, ...this.getObjectJson(obj.children[i])}
				let child = this.getObjectJson(obj.children[i])
				for(let j in child)
					if(typeof json[j] === 'undefined')
						json[j] = child[j]
			}
			// if(obj.json_key) {
			// 	let res = {}
			// 	res[obj.json_key] = json
			// 	return res
			// }
			return json;
		},
		checkForm: function(creator) {
			if(!creator.items.newest) {
				creator.items.targets = this.getTargets(creator)
				creator.items.newest = true
			}
			this.checkObjectsTitle(creator);
			this.checkObjectsName(creator);
			this.checkTargets(creator);
		},
		checkObjectsName: function(creator) {
			creator.items.duplicateTitleObjects.splice(0, creator.items.duplicateTitleObjects.length)
			this.checkSectionsName(creator);
			this.checkTargetsName(creator);
		},
		checkTargetsName: function(creator) {
			if(!creator.items.newest) {
				creator.items.targets = this.getTargets(creator)
			}
			let targets = creator.items.targets
			for(let i in targets) {
				this.setObjectName(creator, targets[i])
			}
			targets.forEach((target, index, arr) => {
				if(arr.findIndex(o=>o.name===target.name) !== index) {
					creator.items.duplicateTitleObjects.push(arr[arr.findIndex(o=>o.name===target.name)], target)
				}
				else if(target.control.errors.duplicate_title) {
					deleteItem(target.control.errors, "duplicate_title")
				}
				target.control.errors = Object.assign({}, target.control.errors, target.control.errors)
			});
			creator.items.duplicateTitleObjects.forEach((target) => {
				target.control.errors.duplicate_title = true
				target.control.errors = Object.assign({}, target.control.errors, target.control.errors)
			})
		},
		checkSectionsName: function(creator) {
			let sections = this.getSections(creator)
			sections.forEach((section, index, arr) => {
				if(arr.findIndex(o=>o.title===section.title) !== index) {
					creator.items.duplicateTitleObjects.push(arr[arr.findIndex(o=>o.title===section.title)], section)
				}
				else if(section.control.errors.duplicate_title) {
					deleteItem(section.control.errors, "duplicate_title")
				}
				section.control.errors = Object.assign({}, section.control.errors, section.control.errors)
			});
			creator.items.duplicateTitleObjects.forEach((section) => {
				section.control.errors.duplicate_title = true
				section.control.errors = Object.assign({}, section.control.errors, section.control.errors)
			})
		},
		checkTargets: function(creator) {
			if(!creator.items.newest) {
				creator.items.targets = this.getTargets(creator)
			}
			for(let i = 0; i < creator.items.unknownTargets.length;) {
				let target = creator.items.unknownTargets[i];
				let content = target.content
				let binding = this.findDescendant(creator, target.binding);
				target = this.findTarget(creator, target.target)
				if(!binding || binding.target) {
					deleteItem(creator.items.unknownTargets, i)
				}
				else if(target) {
					binding.target = {
						id: target.id,
						name: target.name,
						title: target.title,
						type: target.type,
						ancestor_id: target.ancestor_id
					}
					if(!target.bindings.includes(this.getGenealogy(binding)))
						target.bindings.push(this.getGenealogy(binding))
					if(binding.item_type === this.enums.ItemType.RULE_ITEM && target.type in this.setting.rule.effect) {
						binding.content = content
					}
					deleteItem(creator.items.unknownTargets, i)
				}
				else {
					i += 1
				}
			}
		},
		checkObjectsTitle: function(creator) {
			if(!creator.items.newest) {
				creator.items.targets = this.getTargets(creator)
			}
			let objects = [...creator.items.targets, ...this.getSections(creator)]
			objects.forEach((obj) => {
				if(!this.validateTitle(obj.title))
					return false
			})
			return true
		},
		validateTitle: function(title) {
			return !new RegExp(this.setting.invalid_chars.join("|")).test(title)
		},
		checkJsonError: function(creator) {
			var result = {
				success: true,
			};
			if(creator.items.newest) {
				this.checkForm(creator);
			}
			result.success &= creator.items.unknownTargets.length <= 0
			result.success &= creator.items.duplicateTitleObjects.length <= 0
			result.success &= this.checkObjectsTitle(creator)
			if(!result.success) {
				result.msg = "";
				if(creator.items.unknownTargets.length > 0) {
					result.msg += this.language[this.language.lang].message.failed_unknown_target;
				}
				if(result.msg)
					result.msg += `\r\n`
				if(creator.items.duplicateTitleObjects.length > 0) {
					result.msg += this.language[this.language.lang].message.failed_duplicate_title
				}
			}
			return result;
		},
		checkObjectBindings: function(creator, obj) {
			const hasNameObjects = [this.enums.OptionType.EDIT_TEXT, this.enums.OptionType.BI_EDIT_TEXT, this.enums.OptionType.RADIO_GROUP, this.enums.OptionType.CHECK_BOXES, this.enums.OptionType.SELECTOR, this.enums.ImageType.IMAGE]
			if(!hasNameObjects.includes(obj.type))
				return
			if(obj.type === this.enums.OptionType.BI_EDIT_TEXT) {
				obj.editTexts[0].bindings = deepCopy(obj.bindings)
				obj.bindings.splice(0, obj.bindings.length)
				obj = obj.editTexts[0]
			}
			obj.bindings.forEach((binding_genealogy) => {
				let binding = this.findDescendant(creator, binding_genealogy)
				this.setTargetValue(creator, binding, obj)
			})
		},
		setTargetValue: function(creator, binding, target) {
			binding.target = {
				id: target.id,
				name: target.name,
				title: target.title,
				type: this.findDescendant(creator, target.ancestor_id).type === this.enums.OptionType.BI_EDIT_TEXT ? this.enums.OptionType.BI_EDIT_TEXT : target.type,
				ancestor_id: target.ancestor_id
			}
			if(binding.item_type === this.enums.ItemType.RULE_ITEM) {
				binding.content = deepCopy(target.type in this.setting.rule.effect ? this.setting.rule.effect[target.type] : {})
			}
			binding.target = Object.assign({}, binding.target, binding.target)
		},



		getGenealogy: function(obj) {
			return getGenealogy(obj)
		},
		getTargets: function(creator) {
			let targets = []
			for(let i in creator.form.children) {
				switch(creator.form.children[i].object_type) {
					case this.enums.ObjectType.ITEM:
						if(creator.form.children[i].item_type === this.enums.ItemType.SECTION && creator.form.children[i].section_type !== this.enums.SectionType.NOTE_SECTION) {
							targets = [...targets, ...this.getSectionTargets(creator.form.children[i])]
						}
						break;
					case this.enums.ObjectType.LIST:
						if(creator.form.children[i].list_type === this.enums.ListType.SECTIONS) {
							for(let j in creator.form.children[i].children) {
								targets = [...targets, ...this.getSectionTargets(creator.form.children[i].children[j])]
							}
						}
						else if(creator.form.children[i].list_type === this.enums.ListType.IMAGES) {
							for(let j in creator.form.children[i].children) {
								targets.push(creator.form.children[i].children[j])
							}
						}
						break;
				}
			}
			return targets
		},
		getSectionTargets: function(section) {
			let targets = []
			for(let i in section.children[0].children) {
				if(section.children[0].children[i].type === this.enums.OptionType.BI_EDIT_TEXT) {
					for(let j in section.children[0].children[i].editTexts) {
						targets.push(section.children[0].children[i].editTexts[j])
					}
				}
				else if(section.children[0].children[i].type !== this.enums.OptionType.NOTE)
					targets.push(section.children[0].children[i])
				for(let j in section.children[0].children[i].children) {
					targets.push(section.children[0].children[i].children[j])
				}
			}
			return targets
		},
		getSections: function(creator) {
			let sections = []
			sections.push(this.findChild(creator.form, {item_type: this.enums.ItemType.SECTION}))
			sections = [...sections, ...this.findChild(creator.form, {list_type: this.enums.ListType.SECTIONS}).children]
			return sections
		},
		checkItemCond: function(item, cond) {
			let result = true;
			for(let key in cond) {
				if(typeof cond[key] === 'object') {
					for(let k in cond[key]) {
						result &= (item[key][k] === cond[key][k])
					}
				}
				else
					result &= (item[key] === cond[key])
				if(!result)
					break;
			}
			return result
		},
		find: function(list, cond) {
			return list.find((item) => {
				return this.checkItemCond(item, cond)
			})
		},
		findChild: function(parent, cond) {
			return this.find(parent.children, cond);
		},
		findTarget: function(creator, cond) {
			return this.find(creator.items.targets, cond)
		},
		findDescendant: function(creator, genealogy, cond) {
			let obj = creator.form
			for (var i = genealogy.length - 2; i >= 0; i--) {
				let child = this.findChild(obj, genealogy[i]);
				if(typeof child === "undefined") {
					if(obj.item_type === this.enums.ItemType.IMAGE && obj.infos && this.checkItemCond(obj.infos, genealogy[i])) {
						child = obj.infos
					}
					else if(this.setting.rule.option.includes(obj.type) && obj.rules && this.checkItemCond(obj.rules, genealogy[i])) {
						child = obj.rules
					}
					else if(obj.type === this.enums.OptionType.BI_EDIT_TEXT && obj.editTexts) {
						for(let j in obj.editTexts)
							if(this.checkItemCond(obj.editTexts[j], genealogy[i]))
								child = obj.editTexts[j]
					}
					if(typeof child === "undefined")
						return undefined;
				}
				obj = child

				if(!isEmpty(cond)) {
					let result = true;
					for(let key in cond) {
						result &= (obj[key] === cond[key])
						if(!result)
							break;
					}
					if(result)
						break;
				}
			}
			return obj;
		},








		dataOperation: function(creator, input) {
			creator.items.newest = false;
			let obj = this.findDescendant(creator, input.genealogy)
			switch(input.action) {
				case "modify":
					if(input.content.title && !this.validateTitle(input.content.title)) {
						obj.control.errors.invalid_title = true
						input.content.title = input.content.title.replace(new RegExp(this.setting.invalid_chars.join("|")), "");
					}
					else if(obj.control && obj.control.errors.invalid_title) {
						deleteItem(obj.control.errors, 'invalid_title')
					}
					this.setObjectValue(obj, input.content)
					this.checkObjectValue(creator, obj)
					this.checkObjectBindings(creator, obj)
					break;
				case "add":{
					let data = isEmpty(input.content.data) ? {} : input.content.data
					switch(input.content.object_type) {
						case this.enums.ObjectType.LIST:
							switch(input.content.list_type) {
								// sections, options, images, rules, ruleItems, infos, infoItems
								case "sections":
								case "options":
								case "images":
									obj.children.push(this.addList(creator, input.genealogy, input.content.list_type))
									obj.children[obj.children.length - 1].index = obj.children.length - 1
									sortArray(obj.children, "index");
									break;
								case "rules":
								case "ruleItems":
								case "infos":
								case "infoItems":
									break;
							}
							break;
						case this.enums.ObjectType.ITEM:
							switch(input.content.item_type) {
								// section, option, image, rule, ruleItem, infoItem
								case this.enums.ItemType.SECTION:
									data = {...data, ...{ index: obj.children.length }}
									obj.children.push(this.addSection(creator, input.genealogy, input.content.type, data))
									break;
								case this.enums.ItemType.OPTION:
									data = {...data, ...{ index: obj.children.length }}
									obj.children.push(this.addOption(creator, input.genealogy, input.content.type, data))
									break;
								case this.enums.ItemType.IMAGE:
									data = {...data, ...{ index: obj.children.length }}
									obj.children.push(this.addImage(creator, input.genealogy, input.content.type, data))
									break;
								case this.enums.ItemType.RULE:
									break;
								case this.enums.ItemType.RULE_ITEM:
									data = {...data, ...{ index: obj.children.length }}
									obj.children.push(this.addRuleItem(creator, input.genealogy, data))
									break;
								case this.enums.ItemType.INFO_ITEM:
									data = {...data, ...{ index: obj.children.length }}
									obj.children.push(this.addInfoItem(creator, input.genealogy, data))
									break;
							}
							sortArray(obj.children, "index");
							break;
					}
					break;
				}
				case "remove":{
					let index = obj.children.findIndex(o => o.id === input.content.id)
					if((obj.list_type === this.enums.ListType.RULE_ITEMS || obj.list_type === this.enums.ListType.INFO_ITEMS) && obj.children[index].target) {
						let target = this.findDescendant(creator, getGenealogy(obj.children[index].target))
						deleteItem(target.bindings, target.bindings.findIndex(o => isEqual(o, getGenealogy(obj.children[index]))))
					}
					deleteItem(obj.children, index)
					break;
				}
				case "change":{
					let parent = this.findDescendant(creator, obj.ancestor_id)
					parent.children.splice(obj.index, 1, this.addOption(creator, obj.ancestor_id, input.content.type, {
						id: obj.id, 
						index: obj.index, 
						title: obj.type === this.enums.OptionType.BI_EDIT_TEXT ? obj.editTexts[0].title : obj.title,
						bindings: obj.type === this.enums.OptionType.BI_EDIT_TEXT ? obj.editTexts.map(o=>o.bindings).flat() : obj.bindings
					}))
					this.checkObjectValue(creator, parent.children[obj.index])
					this.checkObjectBindings(creator, parent.children[obj.index])
					break;
				}
				case "binding":{
					if(typeof obj.target !== 'undefined') {
						let old_target_genealogy = getGenealogy(obj.target)
						let old_target = this.findDescendant(creator, getGenealogy(obj.target))
						old_target.bindings.splice(old_target.bindings.indexOf(old_target_genealogy), 1)
						obj.content = {}
					}
					if(typeof input.content.target === 'undefined') {
						obj.target = undefined
						break;
					}
					let target = this.findDescendant(creator, input.content.target)
					this.setTargetValue(creator, obj, target)
					// obj.target = {
					// 	id: target.id,
					// 	name: target.name,
					// 	title: target.title,
					// 	type: target.type,
					// 	ancestor_id: target.ancestor_id
					// }
					if(!target.bindings.includes(input.genealogy))
						target.bindings.push(input.genealogy)
					if(obj.item_type === this.enums.ItemType.RULE_ITEM && target.type in this.setting.rule.effect) {
						obj.content = deepCopy(this.setting.rule.effect[target.type])
					}
					break;
				}
				case "sort":
					let oldIndex = input.content.old.index
					let newIndex = input.content.new.index
					switch(input.content.type){
						case "list":
							arrayMove(obj.children, oldIndex, newIndex);
							break;
						case "selector":
							arrayMove(obj.items, oldIndex, newIndex);
							break;
						case "radioGroup":
							arrayMove(obj.items, oldIndex, newIndex);
							break;
						case "checkBoxes":
							arrayMove(obj.items, oldIndex, newIndex);
							break;
						case "biEditText":
							arrayMove(obj.editTexts, oldIndex, newIndex);
							break;
					}
					break;
			}
			this.checkForm(creator);
			this.getJson(creator);
			if(input.record) {
				this.recordStep(creator);
				creator.record.can_save = true;
			}
			this.$forceUpdate()
		},



		toggle: function(creator, input) {
			let obj = this.findDescendant(creator, input.genealogy)
			for(let key in obj.control.preview) {
				if(key in input.content) {
					obj.control.preview[key] = input.content[key];
				}
			}
			if(typeof obj.rules === 'undefined')
				return
			
			let toggle = {}
			let rule_setting_index = this.setting.rule.option.indexOf(obj.type)
			let toggle_key = this.setting.rule.toggle[rule_setting_index]
			toggle[toggle_key] = input.content[this.setting.rule.value_key[rule_setting_index]]
			let rule = this.findChild(obj.rules, {toggle: toggle})
			for(let action_index in rule.children) {
				for(let i in rule.children[action_index].children) {
					let ruleItem = rule.children[action_index].children[i]
					let target = this.findDescendant(creator, getGenealogy(ruleItem.target))
					for(let key in target.control.preview) {
						if(key in ruleItem.content && key in this.setting.rule.effect[target.type]) {
							// if(!isEqual(this.setting.rule.effect[target.type], ruleItem.content)) {
							// 	target.control.preview[key] = ruleItem.content[key];
							// }
							target.control.preview[key] = ruleItem.content[key];
						}
					}
					// console.log(this.setting.rule.action[action_index]);
					switch(this.setting.rule.action[action_index]){
						case 'enable':
							target.control.preview.enable = true;
							break;
						case 'disable':
							target.control.preview.enable = false;
							break;
						case 'visible':
							target.control.preview.visible = true;
							break;
						case 'hidden':
							target.control.preview.visible = false;
							break;
					}
					// target.control.preview.enable = this.setting.rule.action[action_index] === 'enable'
					// target.control.preview.visible = this.setting.rule.action[action_index] === 'visible'
				}
			}
		},
		clearSteps: function(creator) {
			creator.record.steps.splice(0, creator.record.steps.length)
			creator.record.steps.push(deepCopy(creator.json));
			creator.record.now_step = 0
			creator.record.can_redo = false
			creator.record.can_undo = false
			creator.record.can_save = false
		},
		recordStep: function(creator) {
			if(creator.record.now_step !== creator.record.steps.length - 1) {
				creator.record.steps.splice(creator.record.now_step + 1, creator.record.steps.length)//.length = creator.record.now_step + 1;
			}
			creator.record.steps.push(deepCopy(creator.json));
			creator.record.now_step = creator.record.steps.length - 1;
			if(creator.record.steps.length > this.record_max_step) {
				creator.record.steps.shift();
				creator.record.now_step -= 1;
			}
			creator.record.can_redo = (creator.record.now_step >= 0);
			creator.record.can_undo = (creator.record.now_step < creator.record.steps.length - 1);
		},
		redo: function(creator) {
			if(creator.record.now_step <= 0)
				return;
			this.readJson(creator, creator.record.steps[creator.record.now_step - 1]);
			creator.record.now_step -= 1;
			creator.record.can_redo = (creator.record.now_step >= 0);
			creator.record.can_undo = (creator.record.now_step < creator.record.steps.length - 1);
		},
		undo: function(creator) {
			if(creator.record.now_step >= creator.record.steps.length)
				return;
			this.readJson(creator, creator.record.steps[creator.record.now_step + 1]);
			creator.record.now_step += 1;
			creator.record.can_redo = (creator.record.now_step >= 0);
			creator.record.can_undo = (creator.record.now_step < creator.record.steps.length - 1);
		},
	}
}