<template>
	<transition-group name="transit-editText" tag="div" class="flex-row bi-edit-text-wrapper">
		<div :key="editText.id" v-for="editText in biEditText.editTexts">
			<EditText :editText="editText" :enums="enums"/>
		</div>
	</transition-group>
</template>

<script>
	import EditText from './EditText';
	export default {
		name: "BiEditText",
		props: ["biEditText", "enums"],
		components: {
			EditText
		},
		methods: {
		}
	}
</script>

<style scoped>
	.bi-edit-text-wrapper > div {
		width: 50%;
	}
	.transit-editText-move {
  		transition: transform 1.5s;
	}
</style>