<template>
	<div class="flex-row controller">
		<div class="hint">{{hint}}</div><div v-if="hint !== ''">:</div>
		<div class="flex-row control-btn">
		<button class="remove-all-btn" @click="removeAll" v-if="minmax"></button>
		<button class="remove-btn" @click="remove"></button>
		<input type="number" :value="count" readonly>
		<button class="add-btn" @click="add"></button>
		<button class="add-all-btn" @click="addAll" v-if="minmax"></button>
		</div>
	</div>
</template>

<script>
	export default {
		name: "AmountController",
		props: ["hint", "count", "minmax"],
		methods: {
			add: function() {
				this.$emit("add");
			},
			remove: function() {
				if(this.count <= 0)
					return;
				this.$emit("remove");
			},
			addAll: function() {
				this.$emit("addAll");
			},
			removeAll: function() {
				if(this.count <= 0)
					return;
				this.$emit("removeAll");
			},
		}
	}
</script>

<style scoped>
	* {
		background-color: rgba(0,0,0,0);
	}
	.controller {
		background-color: rgba(0,0,0,0);
		text-align: center;
		justify-content: center;
		margin: 5px auto;
		font-size: 1em;
		max-width: 100%;
		/* height: 1em; */
	}
	.controller input[type="number"] {
		text-align: center;
		border: none;
		/*background-color: #fafafa;
		border: 1px solid #ccc;
		border-radius: 1em;*/
		/* padding: 0 0.2em; */
		/* margin: 0 0.1em; */
		width: 2.5em;
		outline: none;
		-moz-appearance: textfield; /*remove input arrow for FireFox*/
	}
	/*remove input arrow*/
	.controller input[type=number]::-webkit-inner-spin-button, 
	.controller input[type=number]::-webkit-outer-spin-button { 
		-webkit-appearance: none; 
		margin: 0; 
	}
	.hint {
		font-size: 1em;
	}
	.control-btn {
		border: 1px solid #ccc;
		border-radius: 1em;
		padding: 0 0.3em;
		margin: 0 0.7em;
		background-color: #fafafa;
		align-content: center;
		justify-content: space-between;
	}
	.add-btn, .remove-btn, .add-all-btn, .remove-all-btn {
		border: none;
		/* height: 1em; */
		/*border: 1px solid #ccc;
		border-radius: 1em;
		padding: 0 0.3em;
		margin: 0 0.1em;
		background-color: #fafafa;*/
	}
	.add-btn:hover:after, .remove-btn:hover:after, .add-all-btn:hover:after, .remove-all-btn:hover:after {
		color: darkblue;
		font-size: 1.1em;
	}
	.add-btn:after {
		color: #2488c6;
		align-content: center;
		content: "\f105";
		font-family: fontAwesome;
		padding: 0 0.25em;
	}
	.remove-btn:after {
		color: #2488c6;
		align-content: center;
		content: "\f104";
		font-family: fontAwesome;
		padding: 0 0.25em;
	}
	.add-all-btn:after {
		color: #2488c6;
		align-content: center;
		content: "\f101";
		font-family: fontAwesome;
		padding: 0 0.25em;
	}
	.remove-all-btn:after {
		color: #2488c6;
		align-content: center;
		content: "\f100";
		font-family: fontAwesome;
		padding: 0 0.25em;
	}
</style>
