<template>
	<div class="image-item-wrapper">
		<div class="image-item-title">{{image.title}}</div>
	</div>
</template>

<script>
	export default {
		name: "ImageItem",
		props: ["image", "enums"],
		components: {
		},
		methods: {
		}
	}
</script>

<style scoped>
	.image-item-wrapper {
		width: 130px;
		height: 90px;
		border: 1px solid #999;
		border-radius: 2px;
		margin: 6px;
	}
	.image-item-title {
		position: absolute;
		bottom: 2px;
		left: 2px;
		/*transform: translate(-50%, -50%);*/
		font-size: 10px;
		font-weight: 500;
		background-color: #ccc;
		padding: 2px 5px;
		border-radius: 2px;
	}
</style>