<template>
	<div id="app">
		<Creator style="height: 100%;"/>
	</div>
</template>

<script>
	import DataMixin from './dataMixin.js';
	import Creator from './plugins/creator/Creator';

	export default {
		name: 'app',
		components: {
			Creator,
		},
		mixins: [DataMixin],
		data() {
			return {
				list: [],
				basic: {},
				data: {},
				view_data: {},
				route: {
					page: 'home', // home, course, unit, form, creator, test
					hierarchy: ["home", "course", "unit", "form", "creator"],
					editable: false, // 0: view, 1: edit
					course_index: -1,
					unit_index: -1,
					form_index: -1,
				}
				/*
				data: {
					type: 'data',
					content: {},
					children: [
						{
							title: '',
							id: '',
							type: 'course',
							visiable: true,
							content: {},
							parent: [],
							children: [
								{
									title: '',
									id: '',
									type: 'unit',
									visiable: true,
									content: {},
									parent: [],
									children: [
										{
											title: '',
											id: '',
											type: 'form',
											visiable: true,
											content: {}
										}
									]
								},
								{}
							]
						},
						{}
					],
				}
				*/
				/*
				json: {
					"course": [
						{
							"title": "CourseName1",
							"unit": [
								{
									"title": "UnitName1",
									"form": [
										{},{}
									]
								}
							]
						}
					]
				}
				*/
			}
		},
		created: function() {
			this.data = this.initData();
			this.getJson(this.data);
			this.updateView();
		},
		methods: {
			changePage: function(select) {
				this.route.page = select.page;
				for(var key in this.route) {
					if(key in select) {
						this.route[key] = select[key];
					}
				}
				this.updateView();
			},
			updateView: function() {
				switch(this.route.page) {
					case "home":
						this.route.course_index = -1,
						this.route.unit_index = -1,
						this.route.form_index = -1,
						this.view_data = this.data;
						break;
					case "course":
						this.route.unit_index = -1,
						this.route.form_index = -1,
						this.view_data = this.data.children[this.route.course_index];
						break;
					case "unit":
						this.route.form_index = -1,
						this.view_data = this.data.children[this.route.course_index].children[this.route.unit_index];
						break;
					case "form":
					case "creator":
						this.view_data = this.data.children[this.route.course_index].children[this.route.unit_index].children[this.route.form_index];
						break;
				}
			},
			importFile: function(file) {
				var self = this;
				var reader = new FileReader();
				reader.onload = function() {
					var obj = JSON.parse(reader.result);
					self.readFile(self.route.page, obj);
				}
				if(file !== undefined) {
					reader.readAsText(file);
				}
			},
			exportFile: function() {
				var download = function (content, filename) {
					let link = document.createElement('a');
					link.download = filename;
					link.style.display = 'none';
					// 字元內容轉變成blob地址
					let blob = new Blob([content]);
					link.href = URL.createObjectURL(blob);
					document.body.appendChild(link);
					link.click();
					document.body.removeChild(link);
				};
				var content = this.getJson(this.view_data);
				var filecontent = JSON.stringify(content);
				var title = this.isEmpty(this.view_data.title) ? "file" : this.view_data.title;
				if ('download' in document.createElement('a')) {
					download(filecontent, title + '.json')
				} else {
					alert('瀏覽器不支援')
				}
			},
			readFile: function(type, obj) {
				switch(type) {
					case "home":
						if(!this.isDataValid(obj)) {
							alert("json格式錯誤");
							break;
						}
						this.data = this.readJson(obj);
						break;
					case "course":
						if(!this.isCourseValid(obj)) {
							alert("json格式錯誤");
							break;
						}
						this.$set(this.data.children, this.route.course_index, this.addCourse(this.getInfo(this.data), obj));
						break;
					case "unit":
						if(!this.isUnitValid(obj)) {
							alert("json格式錯誤");
							break;
						}
						this.$set(this.data.children[this.route.course_index].children, this.route.unit_index, this.addUnit(this.getInfo(this.data.children[this.route.course_index]), obj));
						break;
					case "form":
						if(!this.isFormValid(obj)) {
							alert("json格式錯誤");
							break;
						}
						this.$set(this.data.children[this.route.course_index].children[this.route.unit_index].children, this.route.form_index, this.addForm(this.getInfo(this.data.children[this.route.course_index].children[this.route.unit_index]), obj));
						break;
				}
				this.updateView();
			},
			operation: function(input) {
				this.dataOperation(this.data, input);
				this.$forceUpdate();
			}
		}
	}
</script>

<style scoped>
	.main-nav {
		font-size: 1.2em;
	}
	.footer {
		vertical-align: text-bottom;
	}
</style>