<template>
	<div class="flex-row edit-text-wrapper" :style="getStyle()">
		<Title class="title" :title="editText.title + '：'" :title_style="'text-align:left;left:0;'"/>
		<!--div class="flex-row text-blank" :class="expand ? 'expand' : editText.isFocus && editText.isLong ? '' : editText.isFocus && !editText.isLong ? 'short' : !editText.isFocus && editText.isLong ? 'disable' : 'short disable'"-->
		<div class="flex-row text-blank" :class="getBlankClass()" >
			<!-- <div>{{editText.control.preview.value}}</div> -->
			<div>{{editText.value}}</div>
			<div>{{editText.unit}}</div>
		</div>
	</div>
</template>

<script>
	import Title from './Title';
	export default {
		name: "EditText",
		props: ["editText", "enums"],
		components: {
			Title,
		},
		methods: {
			getBlankClass: function() {
				// console.log(this.editText.control);
				var string = "";
				// if(this.expand)
				// 	string += "expand";
				// else {
					if(!this.editText.isFocus)
						string += " notfocus";
					if(!this.editText.isLong)
						string += " short";
					if(!this.editText.control.preview.enable)
						string += " disable";
				// }
				return string;
			},
			getStyle() {
				if (!this.editText.control.preview.visible) {
					return 'display: none;';
				}
				return '';
			}
		}
	}
</script>

<style scoped>
	.edit-text-wrapper {
		width: 100%;
		justify-content: flex-start;
		align-items: center;
		margin-right: 8px;
	}
	.title {
	}
	.text-blank {
		border-bottom: 1px solid #000;
		flex: 1 1;
		text-align: center;
		justify-content: space-between;
		align-items: flex-end;
		width: 100%;
		height: 1.5em;
	}
	.text-blank.notfocus {
		border-bottom-style: dashed;
	}
	.text-blank.disable {
		border-bottom-color: #aaa;
	}
	.text-blank.disable * {
		color: #aaa;
	}
	.text-blank.short {
		max-width: 40%;
	}
	.text-blank.expand {
		border-bottom: 1px solid #666;
	}
	.unit {
		max-width: 2em;
	}
</style>