function typeOf(obj) {
    const toString = Object.prototype.toString;
    const map = {
        '[object Boolean]'  : 'boolean',
        '[object Number]'   : 'number',
        '[object String]'   : 'string',
        '[object Function]' : 'function',
        '[object Array]'    : 'array',
        '[object Date]'     : 'date',
        '[object RegExp]'   : 'regExp',
        '[object Undefined]': 'undefined',
        '[object Null]'     : 'null',
        '[object Object]'   : 'object'
    };
    return map[toString.call(obj)];
}
// deepCopy
function deepCopy(data) {
    const t = typeOf(data);
    let o;

    if (t === 'array') {
        o = [];
    } else if ( t === 'object') {
        o = {};
    } else {
        return data;
    }

    if (t === 'array') {
        for (let i = 0; i < data.length; i++) {
            o.push(deepCopy(data[i]));
        }
    } else if ( t === 'object') {
        for (let i in data) {
            o[i] = deepCopy(data[i]);
        }
    }
    return o;
}
export {deepCopy};

export function isEmpty(obj) {
    // null and undefined are "empty"
    if (obj == null || typeof obj === "undefined") return true;

    // Assume if it has a length property with a non-zero value
    // that that property is correct.
    if (obj.length > 0)    return false;
    if (obj.length === 0)  return true;

    // If it isn't an object at this point
    // it is empty, but it can't be anything *but* empty
    // Is it empty?  Depends on your application.
    if (typeof obj !== "object") return true;

    // Otherwise, does it have any properties of its own?
    // Note that this doesn't handle
    // toString and valueOf enumeration bugs in IE < 9
    var hasOwnProperty = Object.prototype.hasOwnProperty;
    for (var key in obj) {
        if (hasOwnProperty.call(obj, key)) return false;
    }

    return true;
}

export function sortArray(arr, key=null, reverse=1) {
    arr.sort(function (a, b) {
        let swap;
        if(typeof a !== "object" || typeof b !== "object")
            swap = a > b ? 1 : -1;
        else
            swap = a[key] > b[key] ? 1 : -1;
        return reverse * swap;
    });
}

export function deleteItem(obj, key) {
    if (typeof obj !== "object" || typeof obj[key] === "undefined")
        return;
    if (Array.isArray(obj)) {
        obj.splice(key, 1);
    }
    else {
        delete obj[key];
    }
}

export function setObject(obj, key, value) {
    if (typeof obj !== "object")
        return;
    obj[key] = value
    if (Array.isArray(obj))
        obj.splice(0, obj.length);
    else
        obj = Object.assign({}, obj, obj);
}


export function getGenealogy(obj) {
    let genealogy = deepCopy(obj.ancestor_id)
    genealogy.unshift({id: obj.id})
    return genealogy;
}

export function isEqual(a, b) {
    let result = true
    for(let k in a) {
        if(typeof a[k] === 'object' && typeof b[k] === 'object')
            result &= isEqual(a[k], b[k])
        else
            result &= (a[k] === b[k])
    }
    return result
}

export function arrayMove(arr, old_index, new_index) {
    if (new_index >= arr.length) {
        var k = new_index - arr.length + 1;
        while (k--) {
            arr.push(undefined);
        }
    }
    arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
    return arr;
};
