<template>
	<div class="section" :class="section.type">
		<button class="close-btn" @click="removeSection" v-if="section.section_type!==enums.SectionType.BASIC_SECTION"></button>
		<div class="section-controller" v-if="section.section_type===enums.SectionType.NORMAL_SECTION">
			<button class="control-btn" @click="openOrCloseControlList"><i class="fas fa-ellipsis-v"></i></button>
			<div class="section-controller-list" v-if="open_control">
				<!-- <div class="expansion-btn link" @click="addOrRemoveChild"><i :class="option.children.length > 0 ? 'fas fa-edit' : 'far fa-edit'"></i><font>{{lang.form.option.expansion}}</font></div> -->
				<div class="copy-btn link" @click="copy"><i class="far fa-copy"></i><font>複製</font></div>
			</div>
		</div>
		<Title :title="section.title" :placeholder="lang.form.title.title" @input="inputTitle" @change="changeTitle" :readonly="section.readonly" @focus="focus" @blur="blur" />
		<div class="warning" v-if="section.control.errors.duplicate_title" :title="lang.message.duplicate_title">*{{lang.message.duplicate_title}}</div>
		<div class="warning" v-if="section.control.errors.invalid_title" :title="lang.message.invalid_title">*{{lang.message.invalid_title}}</div>
		<template v-if="section.section_type!==enums.SectionType.NOTE_SECTION">
			<AmountController :hint="lang.form.option.amount" :count="section.children[0].children.length" @add="addOption" @remove="removeOption" />
		</template>

		<template v-for="child in section.children">
			<Item :key="child.id" v-if="child.object_type == enums.ObjectType.ITEM" :item="child" :setting="setting" :targets="targets" :enums="enums" :lang="lang" @dataOperation="dataOperation" @focus="focus" @blur="blur" />
			<List :key="child.id" v-else-if="child.object_type == enums.ObjectType.LIST" :list="child" :setting="setting" :targets="targets" :enums="enums" :lang="lang" @dataOperation="dataOperation" @focus="focus" @blur="blur" />
		</template>
	</div>
</template>

<script>
	import Title from './Title';
	//import Item from './Item';
	import List from './List';
	import AmountController from './AmountController';
	import { getGenealogy } from '../assist';

	export default {
		name: "Section",
		props: ["section", "setting", "targets", "enums", "lang"],
		components: {
			Title,
			Item: () => import('./Item.vue'),
			List,
			AmountController,
		},
		data() {
			return {
				open_control: false
			}
		},
		methods: {
			dataOperation: function(input) {
				this.$emit("dataOperation", input);
			},
			inputTitle: function(e) {
				let input = {
					action: "modify",
					content: {
						title: e,
					},
					record: false,
					genealogy: getGenealogy(this.section)
				};
				this.$emit("dataOperation", input);
			},
			changeTitle: function(e) {
				let input = {
					action: "modify",
					content: {
						title: e,
					},
					record: true,
					genealogy: getGenealogy(this.section)
				};
				this.$emit("dataOperation", input);
			},
			removeSection: function(e) {
				let input = {
					action: "remove",
					content: {
						id: this.section.id
					},
					record: true,
					genealogy: this.section.ancestor_id
				};
				this.$emit("dataOperation", input);
			},
			addOption: function(e) {
				let options = this.section.children.find(o => o.list_type == this.enums.ListType.OPTIONS)
				var input = {
					action: "add",
					content: {
						object_type: this.enums.ObjectType.ITEM,
						item_type: this.enums.ItemType.OPTION,
						type: this.enums.OptionType.EDIT_TEXT,
					},
					record: true,
					genealogy: getGenealogy(options)
				};
				this.$emit("dataOperation", input);
			},
			removeOption: function(e) {
				let options = this.section.children.find(o => o.list_type == this.enums.ListType.OPTIONS)
				if(options.children.length <= 0)
					return;
				var input = {
					action: "remove",
					content: {
						id: options.children[options.children.length - 1].id,
					},
					record: true,
					genealogy: getGenealogy(options)
				};
				this.$emit("dataOperation", input);
			},
			copy: function(e) {

			},
			openOrCloseControlList: function() {
				this.open_control = !this.open_control;
			},
			focus: function() {
				this.$emit('focus')
			},
			blur: function() {
				this.$emit('blur')
			},
		}
	}
</script>

<style scoped>
	.section {
		font-size: 16px;
		background-color: rgba(0,0,0,0);
		border: 2px solid #354E84;
		border-radius: 12px;
		margin: 6px 12px 12px;
		padding: 0 6px 12px;
	}
	.section > div {
		background-color: rgba(0,0,0,0);
	}
	.close-btn {
		background-color: rgba(0,0,0,0);
		margin-left: 0.5em;
		border: none;
		height: 3em;
		position: absolute;
		z-index: 99;
	}
	.close-btn:after {
		color: red;
		align-content: center;
		content: "\f00d";
		font-family: fontAwesome;
	}
	.close-btn:hover:after {
		color: darkred;
		font-size: 1.5em;
	}
	.warning {
		margin: 0;
		width: 100%;
		color: #a33;
		z-index: 99999;
		word-wrap: break-word;
		white-space: nowrap;
		text-overflow: ellipsis;
		overflow: hidden;
		text-align: center;
		font-size: 0.9em;
	}
	.section-controller {
		position: absolute;
		right: 0;
		z-index: 99;
	}
	.section-controller-list {
		z-index: 99;
		position: absolute;
		top: 2.5em;
		right: 0;
		width: 4.5em;
	}
	.section-controller-list > * {
		background-color: #fff;
		padding: 0 5px;
		position: absolute;
		right: 0;
		border: none;
		font-size: 1em;
		height: 1.5em;
		color: #555;
		flex: 0 0 1em;
	}
	.section-controller-list > * * {
		background-color: rgba(0,0,0,0);
	}
	.section-controller-list :hover * {
		color: #000;
	}
	.control-btn {
		position: absolute;
		right: 0;
		border: none;
		font-size: 1em;
		height: 1em;
		color: #555;
		margin-top: 12px;
		flex: 0 0 1em;
		background-color: rgba(0,0,0,0);
	}
</style>