<template>
<div class="radio">
	<div class="flex-column">
		<div class="flex-row main">
			<div class="title">
				<input class="input-text" type="text" :value="radioGroup.title" @input="inputTitle" @change="changeTitle" :placeholder="lang.form.option.title" @focus="focus" @blur="blur">
				<div class="warning" v-if="radioGroup.control.errors.duplicate_title" :title="lang.message.duplicate_title">*{{lang.message.duplicate_title}}</div>
				<div class="warning" v-if="radioGroup.control.errors.invalid_title" :title="lang.message.invalid_title">*{{lang.message.invalid_title}}</div>
			</div>
			<div class="flex-column controller">
				<button class="hidden-btn" @click="showOrHiddenContent"><i :class="show_content ? 'fas fa-caret-down' : 'fas fa-caret-right'"></i></button>
			</div>
			<div class="flex-column">
				<div class="flex-row setting">
					<AmountController :hint="lang.form.option.radioGroup.amount" :count="radioGroup.items.length" @add="addItem" @remove="removeLastItem" style="justify-content: flex-start;" />
				</div>
			</div>
		</div>
	</div>
	<div class="flex-column content" v-if="show_content">
		<draggable v-bind="dragOptions" @end="dragend" handle=".handle">
			<div :key="item.id" v-for="(item, index) in radioGroup.items">
				<div class="flex-row item">
					<i class="fa fa-align-justify handle"></i>
					<button class="check-btn" @click="checkItem">
						<i :index.prop="index" :class="radioGroup.checked===index ? 'fas fa-check-circle selected' : 'far fa-circle'"></i>
						<!-- <i v-if="radioGroup.checked==index" :index.prop="index" class="fas fa-check-circle selected"></i>
						<i v-else class="far fa-circle" :index.prop="index"></i> -->
					</button>
					<input class="input-text" type="text" @input="inputItem" @change="changeItem" :value="item.name" :index.prop="index" :placeholder="lang.form.option.radioGroup.item" @focus="focus" @blur="blur">
					<!-- <button class="rule-btn" :class="open_rule[index] ? 'open' : radioGroup.rules.children[index].empty ? 'close empty' : 'close'" @click="openOrCloseRuleSetting" :index.prop="index">{{lang.form.rule.name}}</button> -->
					<button class="rule-btn" :class="open_rule[index] ? 'open' : isRuleEmpty(radioGroup.rules.children[index]) ? 'empty' : 'closed'" @click="openOrCloseRuleSetting" :index.prop="index">{{lang.form.rule.name}}</button>
					<button class="close-btn px-3" @click="removeItem" :index.prop="index"></button>
				</div>
				<div v-if="open_rule[index]">
					<Rule :rule="radioGroup.rules.children[index]" :controller="radioGroup" :setting="setting" :targets="targets" :enums="enums" :lang="lang" @dataOperation="dataOperation" @focus="focus" @blur="blur"/>
				</div>
			</div>
		</draggable>
	</div>
</div>
</template>

<script>
	import Rule from './Rule';
	import AmountController from './AmountController';
	import { getGenealogy, deepCopy } from '../assist';
	import draggable from 'vuedraggable';

	export default {
		name: "RadioGroup",
		props: ["radioGroup", "setting", "targets", "enums", "lang"],
		components: {
			AmountController,
			Rule,
			draggable
		},
		data() {
			return {
				open_rule: [],
				show_content: true,
			}
		},
		created: function() {
			console.log(this.radioGroup);
			for(let i in this.radioGroup.items) {
				this.open_rule.push(false);
			}
		},
		computed: {
			dragOptions() {
				return {
					group: this.radioGroup.items,
					disabled: false,
					chosenClass: "chosen", // 拖曳目標的class
					dragClass: "dragged", // 拖曳中的class（滑鼠上）
					forceFallback: true,
					animation: "300",
					scrollSensitivity: "100",
				};
			},
		},
		methods: {
			isRuleEmpty: function(rule) {
				for(let action_index in rule.children) {
					if(rule.children[action_index].children.length > 0)
						return false
				}
				return true
			},
			dataOperation: function(input) {
				// console.log(input);
				this.$emit("dataOperation", input);
			},
			dragstart: function() {

			},
			dragend: function(e) {
				var content = this.radioGroup.content;
				var new_index = e.to.index;
				var old_index = e.from.index;
				var temp = content.items[old_index];
				for (let i = old_index; old_index>new_index ? i>new_index : i<new_index; i += old_index>new_index ? -1 : 1) {
					var j = i + (old_index>new_index ? -1 : 1);
					content.items[i] = content.items[j];
				}
				content.items[new_index] = temp;
				for (let i = 0; i < this.radioGroup.content.items.length; i++) {
					this.$set(this.radioGroup.content.items, i, content.items[i]);
				}
				var input = {
					type: "modify",
					content: {
						content: this.radioGroup.content,
					},
					record: true,
					parent: []
				};
				this.$emit("dataOperation", input);
			},
			inputTitle: function(e) {
				let input = {
					action: "modify",
					content: {
						title: e.target.value,
					},
					record: false,
					genealogy: getGenealogy(this.radioGroup)
				};
				this.$emit("dataOperation", input);
			},
			changeTitle: function(e) {
				let input = {
					action: "modify",
					content: {
						title: e.target.value,
					},
					record: true,
					genealogy: getGenealogy(this.radioGroup)
				};
				this.$emit("dataOperation", input);
			},
			inputItem: function(e) {
				let items = deepCopy(this.radioGroup.items)
				items[e.target.index]['name'] = e.target.value;
				let input = {
					action: "modify",
					content: {
						items: items
					},
					record: false,
					genealogy: getGenealogy(this.radioGroup)
				};
				this.$emit("dataOperation", input);
			},
			changeItem: function(e) {
				let items = deepCopy(this.radioGroup.items)
				items[e.target.index]['name'] = e.target.value;
				let input = {
					action: "modify",
					content: {
						items: items,
					},
					record: true,
					genealogy: getGenealogy(this.radioGroup)
				};
				this.$emit("dataOperation", input);
			},
			checkItem: function(e) {
				let checked = e.target.index;
				if(e.target.classList.value.includes("selected")) {
					checked = -1;
				}
				let input = {
					action: "modify",
					content: {
						checked: checked,
					},
					record: true,
					genealogy: getGenealogy(this.radioGroup)
				};
				this.$emit("dataOperation", input);
			},
			addItem: function() {
				let items = deepCopy(this.radioGroup.items)
				items.push({ id: Math.random().toString(36).slice(2), name: "" });
				let input = {
					action: "modify",
					content: {
						items: items
					},
					record: true,
					genealogy: getGenealogy(this.radioGroup)
				};
				this.$emit("dataOperation", input);
			},
			removeLastItem: function() {
				if(this.radioGroup.items.length <= 2)
					return;
				let items = deepCopy(this.radioGroup.items)
				items.pop()
				let checked = this.radioGroup.checked >= items.length ? -1 : this.radioGroup.checked
				var input = {
					action: "modify",
					content: {
						items: items,
						checked: checked
					},
					record: true,
					genealogy: getGenealogy(this.radioGroup)
				};
				this.$emit("dataOperation", input);
			},
			removeItem: function(e) {
				if(this.radioGroup.items.length <= 2)
					return;
				let items = deepCopy(this.radioGroup.items)
				let checked = this.radioGroup.checked >= items.length || this.radioGroup.checked === e.target.index ? -1 : this.radioGroup.checked
				items.splice(e.target.index, 1)
				let input = {
					action: "modify",
					content: {
						items: items,
						checked: checked
					},
					record: true,
					genealogy: getGenealogy(this.radioGroup)
				};
				this.$emit("dataOperation", input);
			},
			openOrCloseRuleSetting: function(e) {
				this.open_rule[e.target.index] = !this.open_rule[e.target.index];
				this.$forceUpdate();
			},
			ruleOperation: function(input) {
				this.$emit("dataOperation", input);
			},
			showOrHiddenContent: function() {
				this.show_content = !this.show_content;
			},
			focus: function() {
				this.$emit('focus')
			},
			blur: function() {
				this.$emit('blur')
			},
			dragend: function(e) {
				let input = {
					action: "sort",
					content: {
						type: "radioGroup",
						old: {
							index: e.oldIndex,
						},
						new: {
							index: e.newIndex,
						}
					},
					record: true,
					genealogy: getGenealogy(this.radioGroup)
				};
				this.$emit("dataOperation", input);
			},
		}
	}
</script>

<style scoped>
	.main > div.flex-column {
		width: 70%;
	}
	.radio {
		width: 100%;
		padding: 0 1em;
		margin: 5px 0 0;
	}
	.title {
		width: 30%;
		margin-right: 0.5em;
	}
	.title:after {
		content: "";
	}
	.input-text {
		line-height: 1em;
	}
	input[type="text"] {
		font-size: 1em;
		width: 100%;
		text-align: center;
		border: none;
		color: #000;
		border-bottom: 2px solid #ccc;
	}
	.content{
		margin: 0;
		width: 100%;
		font-size: 0.9em;
	}
	.content .item {
		margin: 0 5px 2px;
		align-items: center;
		width: 100%;
	}
	.close-btn {
		margin-right: 0.5em;
		background: transparent;
		border: none;
		height: 3em;
		z-index: 99;
	}
	.close-btn:after {
		color: red;
		align-content: center;
		content: "\f00d";
		font-family: fontAwesome;
	}
	.close-btn:hover:after {
		color: darkred;
		font-size: 1.5em;
	}
	.check-btn{
		border: none;
		font-size: 1em;
	}
	.check-btn .selected{
		color: green;
	}
	.chosen {
		background-color: rgba(224, 224, 240, 0.6);
	}
	.dragged {
		background-color: rgba(224, 224, 240, 0.4);
	}
	.setting {
		font-size: 0.9em;
	}
	.setting * {
		font-size: 1em;
	}
	.setting i {
		font-size: 0.8em;
	}
	select {
		margin: 0 2px;
	}
	.expansion-btn {
		border: none;
		font-size: 1.5em;
		color: #555;
	}
	.text-blank {
		border-bottom: 1px solid #000;
		flex: 1 1;
		margin-right: 8px;
		text-align: center;
		min-width: 30%;
	}
	.rule-btn {
		font-size: 0.9em;
		border: 1px solid #333;
		background-color: #333;
		color: #fff;
		border-radius: 1em;
		text-align: center;
		padding: 0 1em;
		margin-left: 1em;
		font-weight: 300;
		line-height: 1em;
	}
	.rule-btn.empty {
		border-color: #999;
		background-color: transparent;
		color: #999;
	}
	.rule-btn.closed {
		border-color: #aaa;
		background-color: #aaa;
		color: #fff;
	}
	.rule-btn.open {
		border-color: #555;
		background-color: #ccc;
		color: #555;
	}
	.rule-btn.empty:after, .rule-btn.closed:after {
		content: "\f105";
		font-family: fontAwesome;
		margin-left: 0.5em;
	}
	.rule-btn.open:after {
		content: "\f107";
		font-family: fontAwesome;
		margin-left: 0.5em;
	}
	.controller {
		flex: 0 0 1em;
	}
	.hidden-btn {
		border: none;
		color: #777;
	}
	.warning {
		margin: 0;
		width: 100%;
		color: #a33;
		z-index: 99999;
		word-wrap: break-word;
		white-space: nowrap;
		text-overflow: ellipsis;
		overflow: hidden;
		font-size: 0.9em;
	}

	.handle {
		float: right;
		padding-top: 8px;
		padding-bottom: 8px;
	}
</style>